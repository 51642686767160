import React, { useState } from "react"
import * as PapaParse from "papaparse"
import moment from "moment-timezone"
import {
    AvailableLookupConsumer,
    ButtonElement,
    DropZoneFileElement,
    IFileToUpload,
    PrimaryText,
    REGEX_CONFIG,
    useCptCodes,
} from "nirvana-react-elements"
import { plainToInstance } from "class-transformer"
import { validate } from "class-validator"

import {
    calculatorSetInputData,
    calculatorSetRunningState,
} from "../../../store/slices/calculator.slice"
import {
    CALCULATOR_CONFIG,
    CoverageCheckerRunningState,
} from "../../../config/calculator.config"
import { PayerCoverageCheckNetwork } from "../../../config/coverage.config"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { LookupService } from "../../../services/lookup.service"
import { RawCsvInputDTO } from "../../../dto/rawCsvInput.dto"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { calculatorSelector } from "../../../store/selectors/calculator.selector"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { profileSelector } from "../../../store/selectors/profile.selector"
import { UtilHelper } from "../../../helpers/util.helper"
import { OrganizationQuoteComponent } from "../../general/quotaChecksProgressComponent.component"
import { CsvHelper } from "../../../helpers/csv.helper"

import fileIcon from "../../../assets/images/icons/file-dark.svg"
import hamburgerIcon from "../../../assets/images/icons/hamburger-white.svg"

export const UploadCsvComponent: React.FunctionComponent<{
    className?: string
}> = props => {
    const dispatch = useAppDispatch()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const calculatorState = useAppSelector(calculatorSelector)
    const profile = useAppSelector(profileSelector)

    const { availableCptCodes } = useCptCodes(
        AvailableLookupConsumer.coveragePortal,
        selectedPracticeRole?.availableModalities
    )

    const [providedCsvFile, setProvidedCsvFile] = useState<IFileToUpload>()

    // https://www.papaparse.com/
    const processCsv = () => {
        if (!providedCsvFile?.rawFile) {
            return
        }

        dispatch(
            calculatorSetRunningState(CoverageCheckerRunningState.PROCESS_CSV)
        )

        const maxBulkCoverageChecks = UtilHelper.isInternalUser(profile)
            ? CALCULATOR_CONFIG.maxBulkCoverageChecksCSVExtended
            : CALCULATOR_CONFIG.maxBulkCoverageChecksCSV

        let foundPayers: IPayer[] = []

        PapaParse.parse(providedCsvFile.rawFile, {
            header: true,

            // Since in our csv we have some random values for header
            // We'd like to transform it to what code expects
            transformHeader(header: string, index: number): string {
                return CALCULATOR_CONFIG.uploadCsvHeaders[index] || header
            },

            complete: async csv => {
                const processedInputData: ICoverageInputData[] = []

                for (const [index, row] of csv.data.entries()) {
                    const rawRow = row as IIndexable

                    // +2 since it's header + code index starts from 0
                    const id = (index + 2).toString()

                    try {
                        const typedRow = plainToInstance(RawCsvInputDTO, row)

                        const validationErrors = (await validate(typedRow))
                            .map(item =>
                                item.constraints
                                    ? Object.values(item.constraints)
                                    : [item.toString()]
                            )
                            .flat()

                        if (
                            processedInputData.length >= maxBulkCoverageChecks
                        ) {
                            break
                        }

                        const {
                            primaryFormat: possibleDob,
                            secondaryFormat: possibleSecondDob,
                            thirdFormat: possibleThirdDob,
                        } = CsvHelper.getPossibleDateField(typedRow.dob)

                        const dobToUse = possibleDob?.isValid()
                            ? possibleDob
                            : possibleSecondDob?.isValid()
                            ? possibleSecondDob
                            : possibleThirdDob?.isValid()
                            ? possibleThirdDob
                            : undefined

                        if (!dobToUse) {
                            validationErrors.push(
                                "Format of DOB is wrong. Should be one of: MM/DD/YYYY or MM/DD/YY or YYYY-MM-DD."
                            )
                        } else if (
                            dobToUse?.isSameOrAfter(moment().startOf("day"))
                        ) {
                            validationErrors.push(
                                "DOB should be more than today."
                            )
                        }

                        let payer: IPayer | undefined

                        // Process payer
                        // Try to find it in lookup
                        if (typedRow.payerExternalId) {
                            const alreadyFound = foundPayers.find(
                                item =>
                                    item.payerId === typedRow.payerExternalId
                            )

                            if (alreadyFound) {
                                payer = alreadyFound
                            } else {
                                const lookupPayerResult =
                                    await LookupService.lookupPayers(
                                        typedRow.payerExternalId
                                    )

                                if (lookupPayerResult.length) {
                                    payer = lookupPayerResult[0]

                                    // Save found payer for the future
                                    // In case next row will need same payer object - we won't need to go to API
                                    foundPayers = [
                                        ...foundPayers,

                                        lookupPayerResult[0],
                                    ]
                                }

                                if (!payer) {
                                    validationErrors.push("Incorrect payer ID")
                                }
                            }
                        }

                        // Check CPT code to use
                        if (
                            !availableCptCodes.find(
                                item => item.value === typedRow.cptCode
                            )
                        ) {
                            validationErrors.push("Not supported CPT code")
                        }

                        const {
                            primaryFormat: primaryNextApptDate,
                            secondaryFormat: secondaryNextApptDate,
                            thirdFormat: thirdNextApptDate,
                        } = CsvHelper.getPossibleDateField(
                            typedRow.customerPatientNextAppointmentDate
                        )

                        const nextApptDateToUse = primaryNextApptDate?.isValid()
                            ? primaryNextApptDate
                            : secondaryNextApptDate?.isValid()
                            ? secondaryNextApptDate
                            : thirdNextApptDate?.isValid()
                            ? thirdNextApptDate
                            : undefined

                        if (
                            nextApptDateToUse?.isSameOrBefore(
                                moment().subtract(1, "day").startOf("day")
                            )
                        ) {
                            validationErrors.push(
                                "Next appointment date should be in the future."
                            )
                        }

                        const neededDob = dobToUse?.isValid()
                            ? UtilHelper.dateToMysqlFormat(dobToUse.toDate())
                            : undefined

                        if (!neededDob) {
                            validationErrors.push(
                                "DOB in CSV is in wrong format. Using fallback value."
                            )
                        }

                        const rowInputData: ICoverageInputData = {
                            id,

                            validationErrors,

                            memberId: typedRow.memberId || undefined,

                            firstName: typedRow.firstName || undefined,
                            lastName: typedRow.lastName || undefined,

                            dob: UtilHelper.dateToMysqlFormat(
                                dobToUse?.toDate() || new Date()
                            ),

                            payer: payer || ({} as IPayer),

                            // Leave as is in $
                            sessionRate: typedRow.sessionCharge?.replace(
                                "$",
                                ""
                            ),
                            cptCode: typedRow.cptCode,

                            inNetworkCheck:
                                typedRow.network !==
                                PayerCoverageCheckNetwork.OUT,

                            outNetworkCheck:
                                typedRow.network ===
                                    PayerCoverageCheckNetwork.OUT ||
                                typedRow.network ===
                                    PayerCoverageCheckNetwork.IN_OUT,

                            customerPatientType:
                                typedRow.customerPatientType || undefined,

                            customerPatientId:
                                typedRow.customerPatientId || undefined,

                            customerPatientNextAppointmentDate:
                                nextApptDateToUse?.isValid()
                                    ? UtilHelper.dateToMysqlFormat(
                                          nextApptDateToUse.toDate()
                                      )
                                    : undefined,

                            passThroughColumns: {},
                        }

                        // Process provider NPI
                        // Set it as custom if regex is correct and it doesn't match practice's group NPI
                        if (
                            typedRow.healthProviderNpi &&
                            REGEX_CONFIG.npi.test(typedRow.healthProviderNpi) &&
                            typedRow.healthProviderNpi !==
                                selectedPracticeRole?.practice.groupNPI
                        ) {
                            rowInputData.customNpi = typedRow.healthProviderNpi
                        }

                        // Divide in and out checks into different rows
                        // It will also make IDs for these different rows uniques
                        if (rowInputData.inNetworkCheck) {
                            processedInputData.push({
                                ...rowInputData,

                                id: rowInputData.outNetworkCheck
                                    ? `${rowInputData.id}_IN`
                                    : rowInputData.id,

                                inNetworkCheck: true,
                                outNetworkCheck: false,
                            })
                        }

                        if (rowInputData.outNetworkCheck) {
                            processedInputData.push({
                                ...rowInputData,

                                id: rowInputData.inNetworkCheck
                                    ? `${rowInputData.id}_OUT`
                                    : rowInputData.id,

                                inNetworkCheck: false,
                                outNetworkCheck: true,
                            })
                        }

                        const resultDownloadExtraHeaders =
                            CsvHelper.getCoverageCheckerDownloadCSVColumns(
                                selectedPracticeRole?.availableModalities
                            ).map(item => item.header)

                        // Process extra columns that are just pass through
                        // We'd need to go through "row" keys - it preserves order
                        // And see which ones are extra (compare to CALCULATOR_CONFIG.uploadCsvHeaders index value)
                        // And just add them to inputData.passThroughColumns
                        // Then during export they will be appended to end of csv
                        // Also make sure not to add headers that are part of our output - in case they reused previously downloaded csv
                        Object.keys(rawRow).forEach((key, index) => {
                            if (
                                index <
                                    CALCULATOR_CONFIG.uploadCsvHeaders.length ||
                                resultDownloadExtraHeaders.includes(key) ||
                                !rowInputData.passThroughColumns
                            ) {
                                return
                            }

                            rowInputData.passThroughColumns[key] = rawRow[key]
                        })
                    } catch (e) {
                        processedInputData.push({
                            id,
                            validationErrors: [
                                `Something went wrong during processing CSV row. ${
                                    e instanceof Error
                                        ? `Error message: ${e.message}.`
                                        : ""
                                } `,
                            ],
                            payer: {} as IPayer,
                        } as ICoverageInputData)
                    }
                }

                // Sort input data - so checks with errors appear first
                processedInputData.sort((x, y) => {
                    const xHasErrors = !!x.validationErrors?.length
                    const yHasErrors = !!y.validationErrors?.length

                    return xHasErrors === yHasErrors ? 0 : xHasErrors ? -1 : 1
                })

                dispatch(calculatorSetInputData(processedInputData))

                // So breathing loader looks good
                await UtilHelper.sleep(2)

                dispatch(
                    calculatorSetRunningState(
                        CoverageCheckerRunningState.PREVIEW_CSV
                    )
                )
            },
        })
    }

    return !calculatorState.coverageChecks.length ? (
        <>
            <OrganizationQuoteComponent
                className="
                    max-w-800px mt-44px
                    md:mt-24px
                "
            />

            <div
                className={`
                    ${props.className}
                    relative max-w-800px
                    p-24px bg-brand-warmLight05
                `}
            >
                <PrimaryText typography="text">
                    Nirvana can process .csv files automatically. For the format
                    that we require, please download template below and also
                    make sure to check out payers that we support
                </PrimaryText>

                <div className="mt-24px flex items-center justify-center">
                    <div className="mr-24px">
                        <a
                            href={
                                CALCULATOR_CONFIG.bulkCoverageChecksTemplateUrl
                            }
                            target="_blank"
                            rel="noreferrer"
                            download
                        >
                            <ButtonElement
                                label="CSV Template"
                                type="default"
                                size="middle"
                                icon={fileIcon}
                                htmlType="button"
                            />
                        </a>
                    </div>

                    <div>
                        <a
                            href={GENERAL_CONFIG.supportedInsurersUrl}
                            target="_blank"
                            rel="noreferrer"
                            download
                        >
                            <ButtonElement
                                label="Supported Payers"
                                type="default"
                                size="middle"
                                icon={fileIcon}
                                htmlType="button"
                            />
                        </a>
                    </div>
                </div>

                <DropZoneFileElement
                    className="mt-32px w-full"
                    withRawFile={true}
                    onFileAccepted={file => setProvidedCsvFile(file)}
                    onFileCancel={() => setProvidedCsvFile(undefined)}
                    acceptMimes={{
                        "text/csv": [],
                    }}
                />

                <ButtonElement
                    className="text-center mt-32px"
                    label="Process"
                    type="primary"
                    size="large"
                    htmlType="button"
                    buttonClassName="w-full"
                    icon={hamburgerIcon}
                    disabled={!providedCsvFile?.rawFile}
                    onClick={processCsv}
                />
            </div>
        </>
    ) : null
}
