/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react"
import { PrimaryText, SpinnerElement } from "nirvana-react-elements"
import useOnclickOutside from "react-cool-onclickoutside"

import hamburgerDark from "../../../assets/images/icons/hamburger-dark-small.svg"

export const UserCommentCardActionsComponent: React.FunctionComponent<
    IUserCommentCardActionsComponentProps
> = props => {
    const containerRef = useOnclickOutside(() => onDeactivate())

    const [isActive, setIsActive] = useState<boolean>(false)

    const onActivate = () => {
        setIsActive(true)
    }

    const onDeactivate = () => {
        setIsActive(false)
    }

    return (
        <div
            ref={containerRef}
            className={`
                relative
                ${props.className}
            `}
        >
            {props.isActionLoading ? (
                <SpinnerElement size="small" />
            ) : (
                <div className="cursor-pointer" onClick={onActivate}>
                    <img src={hamburgerDark} alt="Toggle" />
                </div>
            )}

            {isActive && (
                <div
                    className="
                        absolute z-20 right-0 top-20px w-100px rounded-4px bg-brand-white
                        overflow-hidden border border-solid border-brand-warmShadow
                    "
                >
                    {[
                        {
                            label: "Edit",
                            onClick: props.onEditComment,
                        },
                        // delete can be disabled if there are replies under some comment...
                        ...(props.onDeleteComment
                            ? [
                                  {
                                      label: "Delete",
                                      onClick: props.onDeleteComment,
                                  },
                              ]
                            : []),
                    ].map((item, index) => {
                        return (
                            <div
                                key={index}
                                className="
                                    cursor-pointer px-8px pt-8px pb-4px
                                    hover:bg-brand-offWhite
                                "
                                onClick={() => {
                                    onDeactivate()
                                    item.onClick()
                                }}
                            >
                                <PrimaryText typography="caption">
                                    {item.label}
                                </PrimaryText>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}
