import { UtilHelper } from "nirvana-react-elements"

import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"
import { CalculatorResultType } from "../config/calculator.config"

export class CalculatorService {
    /**
     * Run single coverage check
     */
    static async runCoverageCheck(
        inputData: ICoverageInputData,
        practice: IPractice,
        willRetry: boolean,
        isInNetworkCheck = false
    ): Promise<ICoverageResult | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.COVERAGE_GET_ESTIMATE.replace(
                ":practiceId",
                practice.id.toString()
            ),
            CalculatorService.mapGetEstimateInputData(
                inputData,
                practice,
                isInNetworkCheck
            ),
            "POST",
            undefined,
            undefined,
            undefined,
            willRetry ? [400] : undefined
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Run smart scan coverage check
     */
    static async runSmartScanCoverageCheck(
        requestData: IGetCoverageEstimateData,
        practice: IPractice,
        inNetworkOverride = false
    ): Promise<ICoverageResult | undefined> {
        if (!requestData.firstName || !requestData.lastName) {
            return undefined
        }

        const {
            id,
            payerId,
            customerPatientId,
            customerPatientNextAppointmentDate,
            inNetwork,
            ...rest
        } = requestData

        const result = await HttpHelper.sendRequest(
            API_ROUTES.COVERAGE_GET_SMART_SCAN_ESTIMATE.replace(
                ":practiceId",
                practice.id.toString()
            ),
            {
                ...rest,

                inNetwork:
                    typeof inNetwork === "undefined"
                        ? inNetworkOverride
                        : inNetwork,
            },
            "POST"
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Live coverage check input data to estimate expected data
     */
    static mapGetEstimateInputData(
        inputData: ICoverageInputData,
        practice: IPractice,
        inNetworkCheckOverride?: boolean
    ): IGetCoverageEstimateData {
        const {
            customNpi,
            payer,
            memberId,
            sessionRate,
            inNetworkCheck,
            outNetworkCheck,
            firstName,
            lastName,
            customerPatientId,
            validationErrors,
            passThroughColumns,
            ...rest
        } = inputData

        return {
            ...rest,

            memberId: memberId || undefined,
            customerPatientId: customerPatientId || undefined,
            firstName: firstName || undefined,
            lastName: lastName || undefined,

            providerNpi: customNpi || practice.groupNPI,
            payerId: payer.id,

            inNetwork:
                typeof inNetworkCheckOverride !== "undefined"
                    ? inNetworkCheckOverride
                    : inNetworkCheck,

            // sessionRate is in $, should be converted to cents
            sessionCharge: UtilHelper.formatDollarsToCents(sessionRate),
        }
    }

    /**
     * Get default errored results
     */
    static getDefaultErrorCoverageResult(
        resultId: string,
        payer: IPayer,
        memberId?: string,
        isInNetworkCheck?: boolean
    ): ICoverageResult {
        return {
            resultId,
            memberId: memberId || "",
            payer,
            isInNetworkCheck: isInNetworkCheck || false,

            // From ICoverageResult
            coverageQuotaExceeded: false,
            planType: null,
            planName: null,
            planBeginDate: null,
            possibleMVOBTrigger: false,
            coverageError: null,
            modalityCoverageStatus: null,
            eligibilityBeginDate: null,
            eligibilityEndDate: null,
            relationshipToSubscriber: null,
            override: null,
            flags: [],
            customerPatientType: null,
            customerPatientId: null,
            customerPatientNextAppointmentDate: null,
            thirdPartyPayer: null,
            thirdPartyPayerType: null,
            additionalPolicy: null,
            planResetSoon: null,
            resetBenefitsStatus: null,
            resetBenefits: null,
            priorAuthorization: null,
            visitsTotal: null,
            visitsRemaining: null,
            insuranceType: null,

            // From ICalculatorResult
            planStatus: null,
            modalityCoverageStatusTernary: null,
            enrollmentAllowed: false,
            isError: true,
            resultType: CalculatorResultType.generalError,
            groupName: null,
            groupId: null,
            copayment: null,
            coinsurance: null,
            deductible: null,
            remainingDeductible: null,
            oopMax: null,
            remainingOopMax: null,
            currentSessionRate: null,
            memberObligation: null,
            postDeductibleMemberObligation: null,
            postDeductiblePayerObligation: null,
            postOopMaxMemberObligation: null,
            postOopMaxPayerObligation: null,
            remainingSessionsBeforeDeductible: null,
            remainingSessionsBeforeOopMax: null,
            planEndDate: null,
            isTherapistNirvanaUser: false,
            overriddenPayer: null,
            payerObligation: null,
            createdAt: new Date().getTime(),
        }
    }
}
