import React from "react"

import { FlagBadgeComponent } from "./flagBadge.component"

export const CoverageResultFlagsComponent: React.FunctionComponent<
    ICoverageResultFlagsComponentProps
> = props => {
    return props.flags.length ? (
        <div
            className={`
                flex items-center flex-wrap
                ${props.className}
            `}
        >
            {props.flags.map((item, index) => {
                return (
                    <FlagBadgeComponent
                        key={index}
                        className="mt-4px mr-8px"
                        flagType={item}
                    />
                )
            })}
        </div>
    ) : null
}
