/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useState } from "react"
import { ButtonElement, PrimaryText } from "nirvana-react-elements"

import {
    CALCULATOR_CONFIG,
    CalculatorResultType,
} from "../../../config/calculator.config"
import { PermissionsHelper } from "../../../helpers/permissions.helper"
import { AvailableCoveragePortalPermission } from "../../../config/rolesPermissions.config"
import { calculatorRunSmartCheck } from "../../../store/thunks/calculator.thunks"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { CoverageResultBreakdownDetailsComponent } from "./coverageResultBreakdownDetails.component"

import alertRedIcon from "../../../assets/images/icons/alert-red.svg"
import closeRedIcon from "../../../assets/images/icons/close-red-outlined.svg"

export const CoverageResultBreakdownComponent: React.FunctionComponent<
    ICoverageResultBreakdownComponentProps
> = props => {
    const dispatch = useAppDispatch()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const [isSmartCheckRunning, setIsSmartCheckRunning] =
        useState<boolean>(false)

    const canRunBulkCoverageChecks = useMemo<boolean>(
        () =>
            PermissionsHelper.hasPermission(
                AvailableCoveragePortalPermission.runCsvBulkCoverageChecks,
                selectedPracticeRole
            ),
        [selectedPracticeRole]
    )

    // With all needed data and only if wasn't already run
    const isSmartScanAvailable = useMemo<boolean>(() => {
        return (
            canRunBulkCoverageChecks &&
            !props.coverageResult.isSmartScanResult &&
            !!props.requestData.firstName &&
            !!props.requestData.lastName
        )
    }, [canRunBulkCoverageChecks, props.requestData, props.coverageResult])

    const isActiveCoverage = useMemo<boolean>(() => {
        return CALCULATOR_CONFIG.activeCoverageResults.includes(
            props.coverageResult.resultType
        )
    }, [props.coverageResult])

    const onRunSmartCoverageCheck = useCallback(() => {
        if (!selectedPracticeRole?.practice || !props.requestData) {
            return
        }

        setIsSmartCheckRunning(true)

        dispatch(
            calculatorRunSmartCheck({
                payload: {
                    requestData: props.requestData,
                    inNetwork: props.coverageResult.isInNetworkCheck,
                },
                practice: selectedPracticeRole.practice,
                onSuccess: props.onSmartScanSuccess,
                onFinally: () => setIsSmartCheckRunning(false),
            })
        )
    }, [
        props.requestData,
        props.coverageResult,
        selectedPracticeRole?.practice,
    ])

    const getResultTypeBasedBreakdown = (): JSX.Element => {
        if (isActiveCoverage || props.isOverridesModeActivated) {
            return (
                <CoverageResultBreakdownDetailsComponent
                    coverageResult={props.coverageResult}
                    coverageOverride={props.coverageOverride}
                    requestData={props.requestData}
                    requestPayer={props.requestPayer}
                    responsePayer={props.responsePayer}
                    requestedHealthProvider={props.requestedHealthProvider}
                    policy={props.policy}
                    isOverridesModeActivated={props.isOverridesModeActivated}
                    overridesFormKey={props.overridesFormKey}
                    isOverridesFormDisabled={props.isOverridesFormDisabled}
                    withFlagsSection={props.withFlagsSection}
                />
            )
        }

        // This will be CalculatorResultType.generalError
        let errorTitle: string | JSX.Element = "Something went wrong"
        let errorDescription: string | JSX.Element =
            "The cause of this error might be out of our control. We could not verify the eligibility for benefits at this time."
        let withSmartScan = false
        let alertIcon = alertRedIcon

        // check the different result type
        switch (props.coverageResult.resultType) {
            case CalculatorResultType.noCoverage:
                errorTitle = "Ineligible"
                errorDescription =
                    "This client does not have any active benefits."
                withSmartScan = true
                alertIcon = closeRedIcon

                break

            case CalculatorResultType.activePlanCoverageUnknown:
                errorTitle =
                    CALCULATOR_CONFIG.calculatorResultTypeTitleMapping[
                        CalculatorResultType.activePlanCoverageUnknown
                    ]
                errorDescription =
                    "The plan is Active, however Nirvana is unable to determine if the plan covers benefits."

                break

            case CalculatorResultType.unknownPlanStatus:
                errorTitle =
                    CALCULATOR_CONFIG.calculatorResultTypeTitleMapping[
                        CalculatorResultType.unknownPlanStatus
                    ]
                errorDescription =
                    "Nirvana is unable to determine if the plan is active or covers benefits at this time. Please contact the patient or payer directly. (Status Code: 400)"

                break

            case CalculatorResultType.payerNotSupported:
                errorTitle =
                    CALCULATOR_CONFIG.calculatorResultTypeTitleMapping[
                        CalculatorResultType.payerNotSupported
                    ]
                errorDescription =
                    "Nirvana does not support coverage requests for this payer. (Status Code: 400)"

                break

            case CalculatorResultType.providerNotRecognized:
                errorTitle =
                    CALCULATOR_CONFIG.calculatorResultTypeTitleMapping[
                        CalculatorResultType.providerNotRecognized
                    ]
                errorDescription =
                    "The payer does not recognize the provider NPI - this may be a credentialing issue. Please try another check with a Custom NPI, or contact the payer to resolve. (Status Code: 400)"

                break

            case CalculatorResultType.errorRetrievingCoverage:
                errorTitle =
                    CALCULATOR_CONFIG.calculatorResultTypeTitleMapping[
                        CalculatorResultType.errorRetrievingCoverage
                    ]
                errorDescription =
                    "Nirvana is unable to retrieve coverage information for this plan. Please confirm that the payer and member information are correct and resubmit. (Status Code: 400)"

                break

            case CalculatorResultType.memberIdPayerIdInvalid:
                errorTitle =
                    CALCULATOR_CONFIG.calculatorResultTypeTitleMapping[
                        CalculatorResultType.memberIdPayerIdInvalid
                    ]
                errorDescription =
                    "Nirvana previously located this member ID with a different payer.  Please retry with a different payer ID. (Status Code: 400)"

                break

            case CalculatorResultType.planTypeNotSupported:
                errorTitle =
                    CALCULATOR_CONFIG.calculatorResultTypeTitleMapping[
                        CalculatorResultType.planTypeNotSupported
                    ]
                errorDescription =
                    "Nirvana does not yet support cost-estimates on this plan. (Status Code: 400)"

                break

            case CalculatorResultType.invalidInput:
                errorTitle =
                    CALCULATOR_CONFIG.calculatorResultTypeTitleMapping[
                        CalculatorResultType.invalidInput
                    ]
                errorDescription =
                    "An invalid data format was provided for a request parameter or the parameter was omitted. Details: validation_failures: “field x is a required field”. (Status Code: 422)"

                break

            case CalculatorResultType.unexpectedError:
                errorTitle =
                    CALCULATOR_CONFIG.calculatorResultTypeTitleMapping[
                        CalculatorResultType.unexpectedError
                    ]
                errorDescription =
                    "Nirvana is unable to retrieve coverage information at this time. Please try again and contact Nirvana if the error persists. (Status Code: 500)"

                break

            case CalculatorResultType.tooManyRequests:
                errorTitle =
                    CALCULATOR_CONFIG.calculatorResultTypeTitleMapping[
                        CalculatorResultType.tooManyRequests
                    ]
                errorDescription =
                    "Nirvana is experiencing a high volume of requests. Please try again later. (Status Code: 429)"

                break

            case CalculatorResultType.payerFailure:
                errorTitle =
                    CALCULATOR_CONFIG.calculatorResultTypeTitleMapping[
                        CalculatorResultType.payerFailure
                    ]
                errorDescription =
                    "The payer is currently experiencing downtime. Please try again later and contact Nirvana if the error persists. (Status Code: 500)"

                break

            case CalculatorResultType.unknownDemographics:
                errorTitle =
                    CALCULATOR_CONFIG.calculatorResultTypeTitleMapping[
                        CalculatorResultType.unknownDemographics
                    ]
                errorDescription =
                    "Unable to determine demographics. Provided DOB matches more than one person. (Status Code: 502)"

                break

            case CalculatorResultType.memberNotFound:
                if (isSmartScanAvailable) {
                    withSmartScan = true

                    errorTitle = "No policy found with this payer"
                    errorDescription =
                        "Use a Nirvana Smart Scan™️ to recover policies."
                } else {
                    errorTitle =
                        CALCULATOR_CONFIG.calculatorResultTypeTitleMapping[
                            CalculatorResultType.memberNotFound
                        ]
                    errorDescription =
                        "The payer does not recognize the member. Please confirm that the payer and member information are correct and resubmit. (Status Code: 404)"
                }

                break

            case CalculatorResultType.connectionIssue:
                errorTitle =
                    CALCULATOR_CONFIG.calculatorResultTypeTitleMapping[
                        CalculatorResultType.connectionIssue
                    ]
                errorDescription = (
                    <>
                        The insurance plan is not responding at this time.
                        <br />
                        Please retry later.
                    </>
                )

                break
        }

        return (
            <div className="relative">
                <CoverageResultBreakdownDetailsComponent
                    coverageResult={props.coverageResult}
                    coverageOverride={props.coverageOverride}
                    requestData={props.requestData}
                    requestPayer={props.requestPayer}
                    responsePayer={props.responsePayer}
                    requestedHealthProvider={props.requestedHealthProvider}
                    policy={props.policy}
                    isErrorModeFields={true}
                    withFlagsSection={props.withFlagsSection}
                />

                <div className="py-24px mt-24px">
                    <div className="flex items-center justify-center">
                        <img className="mr-8px" src={alertIcon} alt="Error" />

                        <PrimaryText
                            className="relative top-2px"
                            typography="textSemibold"
                            size={18}
                        >
                            {errorTitle}
                        </PrimaryText>
                    </div>

                    <div className="mt-8px max-w-550px mx-auto">
                        <PrimaryText centered typography="text">
                            {errorDescription}
                        </PrimaryText>
                    </div>

                    {isSmartScanAvailable && withSmartScan ? (
                        <ButtonElement
                            label="Recover policies with Nirvana Smart Scan™"
                            type="primary"
                            htmlType="button"
                            size="large"
                            className="mt-8px text-center"
                            onClick={onRunSmartCoverageCheck}
                            isLoading={isSmartCheckRunning}
                        />
                    ) : null}
                </div>
            </div>
        )
    }

    return (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            {getResultTypeBasedBreakdown()}
        </div>
    )
}
