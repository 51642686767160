import moment from "moment-timezone"
import { UtilHelper as NirvanaUtilHelper } from "nirvana-react-elements"

import { GENERAL_CONFIG } from "../config/general.config"

export class UtilHelper extends NirvanaUtilHelper {
    /**
     * Get pagination for app
     */
    static getPagination(
        count = GENERAL_CONFIG.defaultExtendedPaginationLength
    ): IPagination {
        return {
            start: 0,
            count,
            total: 0,
            moreAvailable: undefined,
        }
    }

    /**
     * Get formatted demographics address
     */
    static getFormattedDemographicsAddress(
        address: ICoverageResultDemographicsAddress
    ): string {
        return [
            address.streetLine1,
            address.streetLine2,
            address.city,
            address.state,
            address.zip?.substring(0, 5),
        ]
            .filter(item => !!item)
            .join(", ")
    }

    /**
     * Check if user is internal
     * internal user is the one that has @meetnirvana.com in the email
     */
    static isInternalUser(profile: IProfile | null): boolean {
        return !!profile?.email.includes(
            `@${GENERAL_CONFIG.supportEmail.split("@")[1]}`
        )
    }

    /**
     * Capitalize each word in sentence
     */
    static capitalizeWordsInSentence(sentence: string): string {
        const words = sentence.trim().split(" ")

        for (let i = 0; i < words.length; i++) {
            if (!words[i]) {
                continue
            }

            words[i] =
                words[i][0].toUpperCase() + words[i].substring(1).toLowerCase()
        }

        return words.join(" ")
    }

    /**
     * Get percentage representative between 2 numbers
     */
    static getPercentageValue(
        part?: number,
        total?: number,
        fractionDigits = 2
    ): number {
        return parseFloat(
            (((part || 0) / (total || 1)) * 100).toFixed(fractionDigits)
        )
    }

    /**
     * Get specified value
     * Need this function to handle 0 as value correctly
     */
    static getSpecifiedNumericValue(
        value?: number | null,
        secondValue?: number | null,
        fallback: null | undefined = null
    ): number | null | undefined {
        return typeof value !== "undefined" && value !== null
            ? value
            : typeof secondValue !== "undefined" && secondValue !== null
            ? secondValue
            : fallback
    }

    /**
     * Calculate relative date range
     * Relative means that there's a range, but then there's "starting identifier" of range
     * And if today is different starting identifier, it will create same range but relative to today
     */
    static calculateRelativeToTodayDateRange(
        relativeToDate: string | number | Date,
        dateFrom?: string | number | Date,
        dateTo?: string | number | Date
    ): {
        dateFrom?: Date
        dateTo?: Date
    } {
        const dateFromDiffDays = dateFrom
            ? moment(relativeToDate)
                  .startOf("day")
                  .diff(moment(dateFrom).startOf("day"), "days")
            : null

        const newDateFrom: Date | undefined = dateFromDiffDays
            ? moment().startOf("day").subtract(dateFromDiffDays, "day").toDate()
            : dateFrom
            ? moment(dateFrom).toDate()
            : undefined

        const dateToDiffDays = dateTo
            ? moment(dateFrom || relativeToDate)
                  .startOf("day")
                  .diff(moment(dateTo).startOf("day"), "days")
            : null

        const newDateTo: Date | undefined = dateToDiffDays
            ? moment(newDateFrom)
                  .startOf("day")
                  .subtract(dateToDiffDays, "day")
                  .toDate()
            : dateTo
            ? moment(dateTo).toDate()
            : undefined

        return {
            dateFrom: newDateFrom,
            dateTo: newDateTo,
        }
    }
}
