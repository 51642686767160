import { ISelectRenderedOption, PolicyModality } from "nirvana-react-elements"

import { TOOLTIPS_CONFIG } from "./tooltips.config"
import { ResetBenefitStatus } from "./policies.config"

import sectionFlagsIcon from "../assets/images/icons/coverage-section-flags.svg"
import sectionGeneralIcon from "../assets/images/icons/coverage-section-general.svg"
import sectionFinancialsIcon from "../assets/images/icons/coverage-section-financials.svg"
import sectionObligationIcon from "../assets/images/icons/coverage-section-obligation.svg"
import sectionSpecialtySpecificIcon from "../assets/images/icons/coverage-section-specialty-specific.svg"

export enum Gender {
    male = "M",
    female = "F",
}

export enum PayerCoverageCheckNetwork {
    IN = "IN",
    OUT = "OUT",
    IN_OUT = "IN_OUT",
}

export enum AvailableModalityCoverageStatus {
    active = "ACTIVE",
    inactive = "INACTIVE",
    unknown = "UNKNOWN",
}

export enum AvailablePriorAuthorizationIndication {
    REQUIRED = "REQUIRED",
    NOT_REQUIRED = "NOT_REQUIRED",
    UNKNOWN = "UNKNOWN",
}

export enum AvailablePlanType {
    ORIGINAL_MEDICAID = "ORIGINAL_MEDICAID",
    MANAGED_MEDICAID = "MANAGED_MEDICAID",
    MEDICARE_ADVANTAGE = "MEDICARE_ADVANTAGE",
    ORIGINAL_MEDICARE = "ORIGINAL_MEDICARE",
    UNKNOWN = "UNKNOWN",
}

export enum AvailableInsuranceType {
    HMO = "HMO",
    PPO = "PPO",
    EPO = "EPO",
    POS = "POS",
}

export enum AvailablePlanStatus {
    unknown = "UNKNOWN",
    active = "ACTIVE",
    activeAtRisk = "ACTIVE_AT_RISK",
    inactive = "INACTIVE",
}

export enum AvailableCoverageErrorCode {
    payerNotSupported = "PAYER_NOT_SUPPORTED",
    payerFailure = "PAYER_FAILURE",
    memberNotFound = "MEMBER_NOT_FOUND",
    memberIdPayerIdInvalid = "MEMBER_ID_PAYER_ID_INVALID",
    errorRetrievingCoverage = "ERROR_RETRIEVING_COVERAGE",
    providerNotRecognized = "PROVIDER_NOT_RECOGNIZED",
    tooManyRequests = "TOO_MANY_REQUESTS",
    unexpectedError = "UNEXPECTED_ERROR",
    invalidInput = "INVALID_INPUT",
    errorProcessingFinancials = "ERROR_PROCESSING_FINANCIALS",
    errorCalculatingDerivedMetrics = "ERROR_CALCULATING_DERIVED_METRICS",
    missingFinancials = "MISSING_FINANCIALS",
    planTypeNotSupported = "PLAN_TYPE_NOT_SUPPORTED",
    unknownPlanStatus = "UNKNOWN_PLAN_STATUS",
    unknownDemographics = "UNKNOWN_DEMOGRAPHICS",
}

export enum CoverageBreakdownSection {
    FLAGS = "FLAGS",
    POLICY_GENERAL = "POLICY_GENERAL",
    POLICY_THIRD_PARTY = "POLICY_THIRD_PARTY",
    POLICY_ADDITIONAL_POLICY = "POLICY_ADDITIONAL_POLICY",
    POLICY_FINANCIALS = "POLICY_FINANCIALS",
    POLICY_OBLIGATION = "POLICY_OBLIGATION",
    SPECIALTY_SPECIFIC = "SPECIALTY_SPECIFIC",
}

export enum CoveragePatientType {
    PROSPECT = "PROSPECT",
    NEW_PATIENT = "NEW_PATIENT",
    EXISTING_PATIENT = "EXISTING_PATIENT",
}

export const COVERAGE_CONFIG = {
    defaultHealthProviderCptCode: "90834",
    defaultHealthProviderRate: 200, // in $

    defaultFallbackCoverageDataValueUnknown: "Unknown",
    defaultFallbackCoverageDataValueNA: "N/A",

    breakdownPlaceholderLabel: "__PLACEHOLDER__",

    payerCoverageCheckNetworkMapped: {
        [PayerCoverageCheckNetwork.IN]: {
            value: PayerCoverageCheckNetwork.IN,
            displayValue: "INN",
            dropdownDisplayValue: "In Network (INN)",
        },
        [PayerCoverageCheckNetwork.OUT]: {
            value: PayerCoverageCheckNetwork.OUT,
            displayValue: "OON",
            dropdownDisplayValue: "Out of Network (OON)",
        },
    } as {
        [key in PayerCoverageCheckNetwork]: ISelectRenderedOption
    },

    get modalityMappings(): Record<PolicyModality, string> {
        return {
            [PolicyModality.MENTAL_HEALTH]: "Mental Health",
            [PolicyModality.EVALUATION_MANAGEMENT]: "Evaluation Management",
            [PolicyModality.PHYSICAL_THERAPY]: "Physical Therapy",
        }
    },

    get availablePlanStatusMapping(): Record<AvailablePlanStatus, string> {
        return {
            [AvailablePlanStatus.active]: "Active",
            [AvailablePlanStatus.activeAtRisk]: "Active at Risk",
            [AvailablePlanStatus.inactive]: "Inactive",
            [AvailablePlanStatus.unknown]: "Unknown",
        }
    },

    get availablePriorAuthorizationMapping(): Record<
        AvailablePriorAuthorizationIndication,
        string
    > {
        return {
            [AvailablePriorAuthorizationIndication.NOT_REQUIRED]:
                "Not Required",
            [AvailablePriorAuthorizationIndication.REQUIRED]: "Required",
            [AvailablePriorAuthorizationIndication.UNKNOWN]: "Unknown",
        }
    },

    get availableModalityCoverageStatusMapping(): Record<
        AvailableModalityCoverageStatus,
        string
    > {
        return {
            [AvailableModalityCoverageStatus.active]: "Active",
            [AvailableModalityCoverageStatus.inactive]: "Inactive",
            [AvailableModalityCoverageStatus.unknown]: "Unknown",
        }
    },

    get coverageBreakdownSections(): Record<
        CoverageBreakdownSection,
        ICoverageBreakdownSectionConfig
    > {
        return {
            [CoverageBreakdownSection.FLAGS]: {
                title: "Alerts",
                withSectionDividerLine: true,
                icon: sectionFlagsIcon,
            },
            [CoverageBreakdownSection.POLICY_GENERAL]: {
                title: "General Information",
                withSectionDividerLine: true,
                icon: sectionGeneralIcon,
                brokenInColumns: true,
            },
            [CoverageBreakdownSection.POLICY_THIRD_PARTY]: {
                title: "Third Party",
                bigFont: true,
                withSectionLeftBorder: true,
                tooltip: TOOLTIPS_CONFIG.coverageBreakdown.thirdPartyPayer,
                singleColumn: true,
            },
            [CoverageBreakdownSection.POLICY_ADDITIONAL_POLICY]: {
                title: "Additional Policy",
                bigFont: true,
                withSectionLeftBorder: true,
                tooltip: TOOLTIPS_CONFIG.coverageBreakdown.additionalPolicy,
                singleColumn: true,
            },
            [CoverageBreakdownSection.POLICY_FINANCIALS]: {
                title: "Financials",
                withSectionDividerLine: true,
                icon: sectionFinancialsIcon,
                brokenInColumns: true,
            },
            [CoverageBreakdownSection.POLICY_OBLIGATION]: {
                title: "Obligation Details",
                withSectionDividerLine: true,
                icon: sectionObligationIcon,
                brokenInColumns: true,
            },
            [CoverageBreakdownSection.SPECIALTY_SPECIFIC]: {
                title: "Specialty Specific",
                withSectionDividerLine: true,
                icon: sectionSpecialtySpecificIcon,
                brokenInColumns: true,
            },
        }
    },

    get selectRenderedPatientTypes(): Record<
        CoveragePatientType,
        ISelectRenderedOption
    > {
        return {
            [CoveragePatientType.NEW_PATIENT]: {
                value: CoveragePatientType.NEW_PATIENT,
                displayValue: "New Patient",
            },
            [CoveragePatientType.PROSPECT]: {
                value: CoveragePatientType.PROSPECT,
                displayValue: "Prospect",
            },
            [CoveragePatientType.EXISTING_PATIENT]: {
                value: CoveragePatientType.EXISTING_PATIENT,
                displayValue: "Existing Patient",
            },
        }
    },

    get selectRenderedResetBenefitStatus(): Record<
        ResetBenefitStatus,
        ISelectRenderedOption
    > {
        return {
            [ResetBenefitStatus.NOT_DETECTED]: {
                value: ResetBenefitStatus.NOT_DETECTED,
                displayValue: "Not detected",
            },
            [ResetBenefitStatus.DETECTED_ACTION_REQUIRED]: {
                value: ResetBenefitStatus.DETECTED_ACTION_REQUIRED,
                displayValue: "Action required",
            },
            [ResetBenefitStatus.DETECTED_NO_ACTION_REQUIRED]: {
                value: ResetBenefitStatus.DETECTED_NO_ACTION_REQUIRED,
                displayValue: "No action needed",
            },
        }
    },
}
