/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react"
import { PopupWrapperElement, PrimaryText } from "nirvana-react-elements"

import { SearchFilterFormComponent } from "../policies/search/searchFilterForm.component"

import closeIcon from "../../assets/images/icons/close-dark.svg"

export const PoliciesFilterPopupComponent: React.FunctionComponent<
    IPoliciesFilterPopupComponentProps
> = props => {
    const [filtersFormKey, setFiltersFormKey] = useState<number>(
        new Date().getTime()
    )

    const [isReset, setIsReset] = useState<boolean>(false)

    const onResetFilters = () => {
        setIsReset(true)
        setFiltersFormKey(new Date().getTime())
    }

    const onFiltersSubmitted = (data: IPoliciesListFiltersData) => {
        props.onFiltersSubmitted(data)
        props.onDone()
    }

    return (
        <PopupWrapperElement
            isActive={props.isActive}
            className={props.className}
            onDone={props.onDone}
            closeOnOutsideClick={props.closeOnOutsideClick}
            popupWidthClassName="
                max-w-1040px! w-full rounded-16px! px-24px! py-16px!
                max-h-screen! min-h-650px!
                md:w-screen! md:h-screen! md:p-16px!
            "
        >
            <div className="flex items-start">
                <PrimaryText
                    typography="h4Medium"
                    className="flex-1 mt-24px mr-24px"
                >
                    Filters
                </PrimaryText>

                <div
                    className="cursor-pointer pt-8px pr-8px"
                    onClick={props.onDone}
                >
                    <img src={closeIcon} alt="Close" title="Close" />
                </div>
            </div>

            <SearchFilterFormComponent
                key={filtersFormKey}
                className="mt-48px w-full"
                withFlags={props.withFlags}
                withResetBenefitsStatus={props.withResetBenefitsStatus}
                onFiltersSubmitted={onFiltersSubmitted}
                existingFilters={!isReset ? props.existingFilters : {}}
                onResetFilters={onResetFilters}
                onCancelFilters={props.onDone}
                submitBtnLabel="Filter"
                persistenceBrowserStorageKey={
                    props.persistenceBrowserStorageKey
                }
                ignorePlanEndDateFilters={props.ignorePlanEndDateFilters}
            />
        </PopupWrapperElement>
    )
}
