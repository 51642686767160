import { createAsyncThunk } from "@reduxjs/toolkit"
import PapaParse from "papaparse"

import {
    runtimeStartLoading,
    runtimeStopLoading,
} from "../slices/runtime.slice"
import {
    policiesIncreaseGetFullListProgress,
    policiesSetGettingFullListProgress,
    policiesSetHistoryList,
    policiesSetOverridesList,
    policiesSetPlanYearResetsList,
    policiesSetReportsList,
    policiesSetSelectedItem,
    policiesSetSelectedItemComments,
} from "../slices/policies.slice"
import { PoliciesService } from "../../services/policies.service"
import { RootState } from "../store"
import { UtilHelper } from "../../helpers/util.helper"
import { PoliciesHelper } from "../../helpers/policies.helper"
import { CsvHelper } from "../../helpers/csv.helper"
import { POLICIES_CONFIG, PoliciesViewType } from "../../config/policies.config"
import { CoverageHistoryHelper } from "../../helpers/coverageHistory.helper"
import { policiesGetSinglePolicyComments } from "./policyComments.thunks"

export const policiesReloadList = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<{
        policiesViewType: PoliciesViewType
    }>
>("policiesReloadListPrefix", async (action, { dispatch, getState }) => {
    const globalState = getState() as RootState

    switch (action.payload.policiesViewType) {
        case PoliciesViewType.REPORTS:
            dispatch(
                policiesGetReportsList({
                    ...action,

                    payload: {
                        pagination: {
                            ...globalState.policies.reportsPagination,

                            // Reload same page
                            start: Math.max(
                                0,
                                globalState.policies.reportsPagination.start -
                                    globalState.policies.reportsPagination.count
                            ),
                        },
                        filters: globalState.policies.reportsFilters,
                        sort: globalState.policies.reportsSort,
                    },
                })
            )

            break

        case PoliciesViewType.OVERRIDES:
            dispatch(
                policiesGetOverridesList({
                    ...action,

                    payload: {
                        pagination: {
                            ...globalState.policies.overridesPagination,

                            // Reload same page
                            start: Math.max(
                                0,
                                globalState.policies.overridesPagination.start -
                                    globalState.policies.overridesPagination
                                        .count
                            ),
                        },
                        filters: globalState.policies.overridesFilters,
                        sort: globalState.policies.overridesSort,
                    },
                })
            )

            break

        case PoliciesViewType.HISTORY_SEARCH:
            if (UtilHelper.isEmptyObject(globalState.policies.historyFilters)) {
                return
            }

            dispatch(
                policiesGetHistoryList({
                    ...action,

                    payload: {
                        pagination: {
                            ...globalState.policies.historyPagination,

                            // Reload same page
                            start: Math.max(
                                0,
                                globalState.policies.historyPagination.start -
                                    globalState.policies.historyPagination.count
                            ),
                        },
                        filters: globalState.policies.historyFilters,
                        sort: globalState.policies.historySort,
                    },
                })
            )

            break

        case PoliciesViewType.PLAN_YEAR_RESETS:
            dispatch(
                policiesGetPlanYearResetsList({
                    ...action,

                    payload: {
                        pagination: {
                            ...globalState.policies.planYearResetsPagination,

                            // Reload same page
                            start: Math.max(
                                0,
                                globalState.policies.planYearResetsPagination
                                    .start -
                                    globalState.policies
                                        .planYearResetsPagination.count
                            ),
                        },
                        filters: globalState.policies.planYearResetsFilters,
                        sort: globalState.policies.planYearResetsSort,
                    },
                })
            )

            break
    }
})

/**
 * Get list of policies for reports
 */
export const policiesGetReportsList = createAsyncThunk<
    ICoverageHistoryListResult,
    IThunkActionWithPracticeData<{
        pagination: IPagination
        filters?: IPoliciesListFiltersData
        sort?: IPoliciesListSortData
    }>
>("policiesGetReportsListPrefix", async (action, { dispatch, signal }) => {
    dispatch(runtimeStartLoading("policiesGetReportsListLoading"))

    const defaultResult: ICoverageHistoryListResult = {
        items: [],
        newPagination: action.payload.pagination,
        isError: false,
    }

    // reset items
    dispatch(policiesSetReportsList(defaultResult))

    try {
        const result = await PoliciesService.getReportsList(
            action.practice,
            action.payload.pagination,
            action.payload.filters,
            action.payload.sort,
            signal
        )

        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }

        return result || defaultResult
    } catch (e) {
        action.onError?.()

        return defaultResult
    } finally {
        // Only stop loading if it wasn't aborted by other trigger
        !signal.aborted &&
            dispatch(runtimeStopLoading("policiesGetReportsListLoading"))
    }
})

/**
 * Get list of historical policies
 */
export const policiesGetHistoryList = createAsyncThunk<
    ICoverageHistoryListResult,
    IThunkActionWithPracticeData<{
        pagination: IPagination
        filters?: IPoliciesListFiltersData
        sort?: IPoliciesListSortData
    }>
>("policiesGetHistoryListPrefix", async (action, { dispatch, signal }) => {
    dispatch(runtimeStartLoading("policiesGetHistoryListLoading"))

    const defaultResult: ICoverageHistoryListResult = {
        items: [],
        newPagination: action.payload.pagination,
        isError: false,
    }

    // reset items
    dispatch(policiesSetHistoryList(defaultResult))

    try {
        const result = await PoliciesService.getHistoryList(
            action.practice,
            action.payload.pagination,
            action.payload.filters,
            action.payload.sort,
            signal
        )

        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }

        return result || defaultResult
    } catch (e) {
        action.onError?.()

        return defaultResult
    } finally {
        // Only stop loading if it wasn't aborted by other trigger
        !signal.aborted &&
            dispatch(runtimeStopLoading("policiesGetHistoryListLoading"))
    }
})

/**
 * Get list of overridden policies
 */
export const policiesGetOverridesList = createAsyncThunk<
    ICoverageHistoryListResult,
    IThunkActionWithPracticeData<{
        pagination: IPagination
        filters?: IPoliciesListFiltersData
        sort?: IPoliciesListSortData
    }>
>("policiesGetOverridesListPrefix", async (action, { dispatch, signal }) => {
    dispatch(runtimeStartLoading("policiesGetOverridesListLoading"))

    const defaultResult: ICoverageHistoryListResult = {
        items: [],
        newPagination: action.payload.pagination,
        isError: false,
    }

    // reset items
    dispatch(policiesSetOverridesList(defaultResult))

    try {
        const result = await PoliciesService.getOverridesList(
            action.practice,
            action.payload.pagination,
            action.payload.filters,
            action.payload.sort,
            signal
        )

        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }

        return result || defaultResult
    } catch (e) {
        action.onError?.()

        return defaultResult
    } finally {
        // Only stop loading if it wasn't aborted by other trigger
        !signal.aborted &&
            dispatch(runtimeStopLoading("policiesGetOverridesListLoading"))
    }
})

/**
 * Get list of plan year resets policies
 */
export const policiesGetPlanYearResetsList = createAsyncThunk<
    ICoverageHistoryListResult,
    IThunkActionWithPracticeData<{
        pagination: IPagination
        filters?: IPoliciesListFiltersData
        sort?: IPoliciesListSortData
    }>
>(
    "policiesGetPlanYearResetsListPrefix",
    async (action, { dispatch, signal }) => {
        dispatch(runtimeStartLoading("policiesGetPlanYearResetsListLoading"))

        const defaultResult: ICoverageHistoryListResult = {
            items: [],
            newPagination: action.payload.pagination,
            isError: false,
        }

        // reset items
        dispatch(policiesSetPlanYearResetsList(defaultResult))

        try {
            const result = await PoliciesService.getPlanYearResetsList(
                action.practice,
                action.payload.pagination,
                action.payload.filters,
                action.payload.sort,
                signal
            )

            if (result) {
                action.onSuccess && action.onSuccess(result)
            } else {
                action.onError && action.onError(result)
            }

            return result || defaultResult
        } catch (e) {
            action.onError?.()

            return defaultResult
        } finally {
            // Only stop loading if it wasn't aborted by other trigger
            !signal.aborted &&
                dispatch(
                    runtimeStopLoading("policiesGetPlanYearResetsListLoading")
                )
        }
    }
)

/**
 * Get aggregations of plan year resets
 */
export const policiesGetPlanYearResetsAggregations = createAsyncThunk<
    IPlanYearResetAggregations | null,
    IThunkActionWithPracticeData<{
        filters?: IPoliciesListFiltersData
    }>
>(
    "policiesGetPlanYearResetsAggregationsPrefix",
    async (action, { dispatch, signal }) => {
        dispatch(
            runtimeStartLoading("policiesGetPlanYearResetsAggregationsLoading")
        )

        try {
            const result = await PoliciesService.getPlanYearResetsAggregations(
                action.practice,
                action.payload.filters,
                signal
            )

            if (result) {
                action.onSuccess && action.onSuccess(result)
            } else {
                action.onError && action.onError(result)
            }

            return result || null
        } catch (e) {
            action.onError?.()

            return null
        } finally {
            // Only stop loading if it wasn't aborted by other trigger
            !signal.aborted &&
                dispatch(
                    runtimeStopLoading(
                        "policiesGetPlanYearResetsAggregationsLoading"
                    )
                )
        }
    }
)

/**
 * Get aggregations of reports by status codes
 */
export const policiesGetReportsAggregationsStatusCodes = createAsyncThunk<
    IGetCoverageChecksAggregationsStatusCodes | null,
    IThunkActionWithPracticeData<{
        filters?: IPoliciesListFiltersData
    }>
>(
    "policiesGetReportsAggregationsStatusCodesPrefix",
    async (action, { dispatch, signal }) => {
        dispatch(
            runtimeStartLoading(
                "policiesGetReportsAggregationsStatusCodesLoading"
            )
        )

        try {
            const result =
                await PoliciesService.getReportsAggregationStatusCodes(
                    action.practice,
                    action.payload.filters,
                    signal
                )

            if (result) {
                action.onSuccess && action.onSuccess(result)
            } else {
                action.onError && action.onError(result)
            }

            return result || null
        } catch (e) {
            action.onError?.()

            return null
        } finally {
            // Only stop loading if it wasn't aborted by other trigger
            !signal.aborted &&
                dispatch(
                    runtimeStopLoading(
                        "policiesGetReportsAggregationsStatusCodesLoading"
                    )
                )
        }
    }
)

/**
 * Get aggregations of reports by flags
 */
export const policiesGetReportsAggregationsFlags = createAsyncThunk<
    IGetCoverageChecksAggregationsFlags | null,
    IThunkActionWithPracticeData<{
        filters?: IPoliciesListFiltersData
    }>
>(
    "policiesGetReportsAggregationsFlagsPrefix",
    async (action, { dispatch, signal }) => {
        dispatch(
            runtimeStartLoading("policiesGetReportsAggregationsFlagsLoading")
        )

        try {
            const result = await PoliciesService.getReportsAggregationFlags(
                action.practice,
                action.payload.filters,
                signal
            )

            if (result) {
                action.onSuccess && action.onSuccess(result)
            } else {
                action.onError && action.onError(result)
            }

            return result || null
        } catch (e) {
            action.onError?.()

            return null
        } finally {
            // Only stop loading if it wasn't aborted by other trigger
            !signal.aborted &&
                dispatch(
                    runtimeStopLoading(
                        "policiesGetReportsAggregationsFlagsLoading"
                    )
                )
        }
    }
)

/**
 * Get single historical policy
 */
export const policiesGetSinglePolicy = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<{
        triesCount?: number
        retryMaxCount?: number
        nirvanaRequestId: string
        withPolicyDataReFetch?: boolean
    }>
>("policiesGetSinglePolicyPrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("policiesGetSinglePolicyLoading"))

    const triesCount = action.payload.triesCount || 0

    const { retryMaxCount, nirvanaRequestId, withPolicyDataReFetch } =
        action.payload

    try {
        // Reset comments
        dispatch(policiesSetSelectedItemComments([]))

        const needRetry = !!retryMaxCount && triesCount <= retryMaxCount

        const result = await PoliciesService.getSingle(
            action.practice,
            nirvanaRequestId,
            withPolicyDataReFetch,
            needRetry ? [400] : undefined
        )

        if (result) {
            dispatch(policiesSetSelectedItem(result))

            action.onSuccess && action.onSuccess(result)

            // Get comments for policy/patient if we have needed data
            const patient =
                CoverageHistoryHelper.getPatientIdentificationData(result) ||
                undefined

            const policy =
                CoverageHistoryHelper.getPolicyIdentificationData(result) ||
                undefined

            if (patient || policy) {
                dispatch(
                    policiesGetSinglePolicyComments({
                        practice: action.practice,
                        payload: {
                            patient,
                            policy,
                        },
                    })
                )
            }
        } else {
            // Retry if needed
            if (needRetry) {
                await UtilHelper.sleep(1)

                dispatch(
                    policiesGetSinglePolicy({
                        ...action,

                        payload: {
                            ...action.payload,
                            triesCount: triesCount + 1,
                        },
                    })
                )

                return
            }

            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("policiesGetSinglePolicyLoading"))
    }
})

/**
 * Retry single policy
 */
export const policiesRetrySinglePolicy = createAsyncThunk<
    ICoverageResult | null,
    IThunkActionWithPracticeData<{
        nirvanaRequestId: string
    }>
>("policiesRetrySinglePolicyPrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("policiesRetrySinglePolicyLoading"))

    try {
        const result = await PoliciesService.retrySingle(
            action.practice,
            action.payload.nirvanaRequestId
        )

        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }

        return result || null
    } catch (e) {
        action.onError?.()

        return null
    } finally {
        dispatch(runtimeStopLoading("policiesRetrySinglePolicyLoading"))
    }
})

/**
 * Resolve policy flag
 */
export const policiesResolveFlag = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<{
        flag: IPolicyFlag
    }>
>("policiesResolveFlagPrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("policiesResolveFlagLoading"))

    try {
        const result = await PoliciesService.resolveFlag(
            action.practice,
            action.payload.flag.id
        )

        if (result) {
            dispatch(policiesSetSelectedItem(result))

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("policiesResolveFlagLoading"))
    }
})

/**
 * Export all policies with applied filters and sort to CSV
 */
export const policiesExportList = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<{
        viewType: PoliciesViewType
        columns: IPolicyColumnConfiguration[]
    }>
>("policiesExportListPrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("policiesExportListLoading"))

    try {
        const fetchedItems = await dispatch(
            policiesGetFullList({
                practice: action.practice,
                payload: {
                    type: action.payload.viewType,
                },
            })
        ).unwrap()

        const csvData: any[][] = []

        // headers
        csvData.push(action.payload.columns.map(item => item.label))

        // data
        for (const coverageCheck of fetchedItems) {
            const row: any[] = []

            for (const column of action.payload.columns) {
                row.push(
                    PoliciesHelper.getMappedColumnValue(
                        coverageCheck,
                        column,
                        "",
                        false
                    )
                )
            }

            csvData.push(row)
        }

        CsvHelper.createAndDownloadCsvDocument(
            PapaParse.unparse(csvData),
            POLICIES_CONFIG.exportPoliciesBaseName
        )

        if (fetchedItems.length) {
            action.onSuccess && action.onSuccess(fetchedItems)
        } else {
            action.onError && action.onError(fetchedItems)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("policiesExportListLoading"))

        action.onFinally?.()
    }
})

/**
 * Get full list of policies of some type - based on filters and sort in state
 */
export const policiesGetFullList = createAsyncThunk<
    ICoverageCheckHistory[],
    IThunkActionWithPracticeData<{
        type: PoliciesViewType
    }>
>(
    "policiesGetFullListPrefix",
    async (
        action,
        { dispatch, getState }
    ): Promise<ICoverageCheckHistory[]> => {
        dispatch(policiesSetGettingFullListProgress(0))
        dispatch(runtimeStartLoading("policiesGetFullListLoading"))

        const globalState = getState() as RootState

        let progressIntervalId: NodeJS.Timer | undefined
        const fetchedItems: ICoverageCheckHistory[] = []

        try {
            let result:
                | {
                      items: ICoverageCheckHistory[]
                      newPagination: IPagination
                      isError: boolean
                  }
                | undefined

            const neededPaginationLength =
                POLICIES_CONFIG.availablePaginationOptions[
                    POLICIES_CONFIG.availablePaginationOptions.length - 1
                ].value

            do {
                switch (action.payload.type) {
                    case PoliciesViewType.REPORTS:
                        result = await PoliciesService.getReportsList(
                            action.practice,
                            result?.newPagination ||
                                UtilHelper.getPagination(
                                    neededPaginationLength
                                ),
                            globalState.policies.reportsFilters,
                            globalState.policies.reportsSort
                        )

                        break

                    case PoliciesViewType.HISTORY_SEARCH:
                        result = await PoliciesService.getHistoryList(
                            action.practice,
                            result?.newPagination ||
                                UtilHelper.getPagination(
                                    neededPaginationLength
                                ),
                            globalState.policies.historyFilters,
                            globalState.policies.historySort
                        )

                        break

                    case PoliciesViewType.OVERRIDES:
                        result = await PoliciesService.getOverridesList(
                            action.practice,
                            result?.newPagination ||
                                UtilHelper.getPagination(
                                    neededPaginationLength
                                ),
                            globalState.policies.overridesFilters,
                            globalState.policies.overridesSort
                        )

                        break

                    case PoliciesViewType.PLAN_YEAR_RESETS:
                        result = await PoliciesService.getPlanYearResetsList(
                            action.practice,
                            result?.newPagination ||
                                UtilHelper.getPagination(
                                    neededPaginationLength
                                ),
                            globalState.policies.planYearResetsFilters,
                            globalState.policies.planYearResetsSort
                        )

                        break
                }

                if (result?.items.length) {
                    fetchedItems.push(...result.items)
                }

                // Notify progress and set interval for auto increment of progress
                if (result?.newPagination.total) {
                    dispatch(
                        policiesSetGettingFullListProgress(
                            Math.max(
                                (getState() as RootState).policies
                                    .policiesGettingFullListProgress,
                                Math.floor(
                                    (fetchedItems.length /
                                        result.newPagination.total) *
                                        100
                                )
                            )
                        )
                    )

                    // done so it doesn't stuck on some value for single chunk export
                    // Also so it doesn't jump a lot
                    if (!progressIntervalId) {
                        // So calculations for interval below is approximate, based on average values and scaling factor.
                        //
                        // Time it takes to fetch records:
                        //     Total policies: 20000
                        //     100 policies -> 14000 ms
                        //
                        //     Total policies: 1000
                        //     100 policies -> around 4200 ms
                        //
                        // Interval:
                        //     Updates +1% every X seconds
                        //     We need interval:
                        //     total time to fetch all records approximately: (total / 100) * timeToFetch100
                        //
                        // If for total 1000 records it takes 42000ms to load, if for total 20000 records it takes 2800000ms. What would be formula by calculating total time for any amount of total records.
                        // Given that the time per record appears to increase as the total number of records increases, we need to consider a scaling factor. We can attempt to fit this relationship using a scaling law, such as a quadratic or polynomial relationship.
                        //
                        // The general formula to calculate the total time to load any number of records N is (got it through different calculations):
                        //
                        //     T(N) = 36.842 * N + 0.005158 * Math.pow(N, 2)
                        //
                        // Examples:
                        //     1000 -> 36.842 * N + 0.005158 * Math.pow(N, 2) = 42 * 1000 ms
                        //     5000 -> 36.842 * N + 0.005158 * Math.pow(N, 2) = 184210 + 0.005158 * 25000000 = 313 160 ms
                        //     20 000 -> 36.842 * N + 0.005158 * Math.pow(N, 2) = 736840 + 0.005158 * 400000000 = 2800040 ms
                        //
                        // And T(N) / 100 -> our interval time to approximately update 1% up

                        const progressIntervalValueMs =
                            (36.842 * result.newPagination.total +
                                0.005158 *
                                    Math.pow(result.newPagination.total, 2)) /
                            100

                        progressIntervalId = setInterval(() => {
                            dispatch(policiesIncreaseGetFullListProgress())
                        }, progressIntervalValueMs)
                    }
                }
            } while (result?.newPagination.moreAvailable)

            if (fetchedItems.length) {
                action.onSuccess && action.onSuccess(fetchedItems)
            } else {
                action.onError && action.onError(fetchedItems)
            }

            return fetchedItems
        } catch (e) {
            return fetchedItems
        } finally {
            progressIntervalId && clearInterval(progressIntervalId)

            dispatch(policiesSetGettingFullListProgress(100))
            dispatch(runtimeStopLoading("policiesGetFullListLoading"))

            action.onFinally?.()
        }
    }
)
