/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import {
    BrowserStorageHelper,
    BrowserStorageType,
    PrimaryText,
} from "nirvana-react-elements"

import { useAppSelector } from "../../store/selectors/app.selector"
import { policiesSelector } from "../../store/selectors/policies.selector"
import { useScrollableElementBoundaries } from "../../hooks/scrollableElementBoundaries.hook"

import chevronDown from "../../assets/images/icons/chevron-down-dark.svg"
import chevronUp from "../../assets/images/icons/chevron-up-dark.svg"

export const VisualizationWrapperComponent: React.FunctionComponent<
    IVisualizationWrapperComponentProps
> = props => {
    const { visualizationsCollapseTrigger } = useAppSelector(policiesSelector)
    const tableBoundaries = useScrollableElementBoundaries(
        props.adjustHorizontalScrollReference
    )

    const [isCollapsed, setIsCollapsed] = useState<boolean>(
        !!BrowserStorageHelper.get(
            props.collapseStateBrowserStorageKey,
            false,
            BrowserStorageType.sessionStorage
        )
    )

    // Save collapsed state to session storage
    useEffect(() => {
        BrowserStorageHelper.set(
            props.collapseStateBrowserStorageKey,
            isCollapsed,
            BrowserStorageType.sessionStorage
        )
    }, [isCollapsed])

    // When collapse trigger is updated - hide visualizations
    useEffect(() => {
        if (!visualizationsCollapseTrigger) {
            return
        }

        setIsCollapsed(true)
    }, [visualizationsCollapseTrigger])

    const toggleCollapse = (value?: boolean) => {
        setIsCollapsed(current =>
            typeof value !== "undefined" ? value : !current
        )
    }

    return (
        <div
            className={`
                relative
                border-t border-solid border-brand-warmShadow
                ${isCollapsed ? "pb-30px" : "border-b"}
                ${props.className}
            `}
            style={{
                right: tableBoundaries?.x,
            }}
        >
            <div
                className="
                    absolute z-20 top--15px horizontal-center cursor-pointer
                    rounded-4px py-5px px-16px bg-white
                    border border-solid border-brand-primary
                    flex items-center
                "
                onClick={() => toggleCollapse()}
            >
                <PrimaryText
                    className="relative top-2px mr-8px"
                    typography="buttonText"
                    centered
                >
                    {isCollapsed ? "Show" : "Hide"} Overview
                </PrimaryText>

                <img src={isCollapsed ? chevronDown : chevronUp} alt="Toggle" />
            </div>

            <div
                className={`
                    flex items-start
                    ${isCollapsed ? "h-0 hidden" : "h-auto"}
                `}
            >
                {props.children}
            </div>
        </div>
    )
}
