/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import {
    AvailableLookupConsumer,
    ButtonElement,
    NirvanaBreathingLoaderElement,
    PageHelmetElement,
    PrimaryText,
    SpinnerElement,
    useCptCodes,
    useIsMobile,
    useScrollFix,
} from "nirvana-react-elements"

import {
    calculatorReset,
    calculatorToggleRunningType,
} from "../../store/slices/calculator.slice"
import {
    CoverageCheckerMode,
    CoverageCheckerRunningState,
} from "../../config/calculator.config"
import { calculatorSelector } from "../../store/selectors/calculator.selector"
import { GENERAL_CONFIG } from "../../config/general.config"
import { useAppSelector } from "../../store/selectors/app.selector"
import { runtimeSelector } from "../../store/selectors/runtime.selector"
import { RuntimeHelper } from "../../helpers/runtime.helper"
import { ResultsDownloadComponent } from "./resultsDownload.component"
import { PermissionsHelper } from "../../helpers/permissions.helper"
import { AvailableCoveragePortalPermission } from "../../config/rolesPermissions.config"
import { CoverageCheckerCSVComponent } from "./csv/coverageCheckerCSV.component"
import { CoverageCheckerManualComponent } from "./manual/coverageCheckerManual.component"
import { profileSelector } from "../../store/selectors/profile.selector"
import { selectedPracticeRoleSelector } from "../../store/selectors/selectedPracticeRole.selector"
import { coveragePayersGetList } from "../../store/thunks/coveragePayers.thunks"
import { useAppDispatch } from "../../store/appDispatch.hook"

import refreshIcon from "../../assets/images/icons/refresh-dark.svg"
import uploadIconWhite from "../../assets/images/icons/upload-white.svg"
import uploadIconDark from "../../assets/images/icons/upload-dark.svg"
import magicWandIconWhite from "../../assets/images/icons/magic-wand-white.svg"
import magicWandIconDark from "../../assets/images/icons/magic-wand-dark.svg"

export const CoverageCheckerComponent: React.FunctionComponent = () => {
    useScrollFix()

    const dispatch = useAppDispatch()

    const [searchParams, setSearchParams] = useSearchParams()

    const isMobile = useIsMobile()

    const profile = useAppSelector(profileSelector)
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const calculatorState = useAppSelector(calculatorSelector)
    const runtimeState = useAppSelector(runtimeSelector)

    const { availableCptCodes } = useCptCodes(
        AvailableLookupConsumer.coveragePortal,
        selectedPracticeRole?.availableModalities
    )

    const canRunCoverageChecks = useMemo<boolean>(
        () =>
            PermissionsHelper.hasPermission(
                [
                    AvailableCoveragePortalPermission.runCoverageChecks,
                    AvailableCoveragePortalPermission.runCsvBulkCoverageChecks,
                ],
                selectedPracticeRole
            ),
        [selectedPracticeRole]
    )

    const canRunBulkCoverageChecks = useMemo<boolean>(
        () =>
            PermissionsHelper.hasPermission(
                AvailableCoveragePortalPermission.runCsvBulkCoverageChecks,
                selectedPracticeRole
            ),
        [selectedPracticeRole]
    )

    // If received more than X results -> stop loader, so user can preview results already
    // Also show spinner if processing CSV right now
    const isLoading = useMemo<boolean>(() => {
        return calculatorState.coverageChecks.filter(item => !!item.result)
            .length > 5
            ? false
            : RuntimeHelper.isBulkCoverageChecksLoading() ||
                  calculatorState.runningState ===
                      CoverageCheckerRunningState.PROCESS_CSV
    }, [
        runtimeState.isLoading,
        calculatorState.coverageChecks,
        calculatorState.runningState,
    ])

    // Listen for browser back btn -> reset Coverage Checker page
    useEffect(() => {
        window.addEventListener("popstate", onReset)

        return () => {
            window.removeEventListener("popstate", onReset)
        }
    }, [])

    // Check if manual or csv flow was requested by default from url
    useEffect(() => {
        const checkerMode = searchParams.get(
            GENERAL_CONFIG.urlSearchParamsKeys.checkerMode
        )

        // If some random value was provided
        if (
            !checkerMode ||
            !(Object.values(CoverageCheckerMode) as string[]).includes(
                checkerMode
            )
        ) {
            return
        }

        onToggleRunningType(checkerMode as CoverageCheckerMode)
    }, [searchParams])

    // If user is not allowed to run bulk coverage checks
    // We should switch them to manual input
    useEffect(() => {
        if (canRunBulkCoverageChecks || !selectedPracticeRole) {
            return
        }

        onToggleRunningType(CoverageCheckerMode.MANUAL)
    }, [canRunBulkCoverageChecks, selectedPracticeRole])

    // Load fresh coverage payers when selectedPracticeRole changes
    useEffect(() => {
        if (!selectedPracticeRole) {
            return
        }

        dispatch(
            coveragePayersGetList({
                payload: undefined,
                practice: selectedPracticeRole.practice,
            })
        )
    }, [selectedPracticeRole?.id])

    const onResetSearchParams = () => {
        // Remove results search params flag - it's added when triggering new results check
        searchParams.delete(GENERAL_CONFIG.urlSearchParamsKeys.results)
        setSearchParams(searchParams)
    }

    const onReset = () => {
        dispatch(calculatorReset())
        onResetSearchParams()
    }

    const onToggleRunningType = (checkerMode?: CoverageCheckerMode) => {
        dispatch(
            calculatorToggleRunningType({
                checkerMode,
            })
        )

        onResetSearchParams()
    }

    const headerTitle = useMemo<string>(() => {
        switch (calculatorState.runningState) {
            case CoverageCheckerRunningState.PREVIEW_CSV:
                return "Preview Checks"

            default:
                return "Nirvana Coverage Checker"
        }
    }, [calculatorState.runningState])

    const headerSubtitle = useMemo<JSX.Element>(() => {
        switch (calculatorState.runningState) {
            case CoverageCheckerRunningState.PREVIEW_CSV:
                return (
                    <>
                        You can remove records before you run the checks.
                        <br className="md:hidden" /> Note that any checks with
                        errors will not be included in the run.
                    </>
                )

            default:
                return (
                    <>
                        Find out how much your clients can expect to be
                        <br className="md:hidden" />
                        reimbursed for therapy after their insurance kick in.
                    </>
                )
        }
    }, [calculatorState.runningState])

    const headerActions = useMemo<JSX.Element | null>(() => {
        const isResultsView = [
            CoverageCheckerRunningState.RESULTS_MANUAL,
            CoverageCheckerRunningState.RESULTS_CSV,
        ].includes(calculatorState.runningState)

        const uploadCsvBtn = (
            <ButtonElement
                label="Upload CSV"
                type={isResultsView ? "default" : "primary"}
                size="large"
                icon={isResultsView ? uploadIconDark : uploadIconWhite}
                htmlType="button"
                onClick={() => onToggleRunningType(CoverageCheckerMode.CSV)}
            />
        )

        const enterDataManuallyBtn = (
            <ButtonElement
                label="Run Eligibility Check"
                type={isResultsView ? "default" : "primary"}
                size="large"
                icon={isResultsView ? magicWandIconDark : magicWandIconWhite}
                htmlType="button"
                onClick={() => onToggleRunningType(CoverageCheckerMode.MANUAL)}
            />
        )

        switch (calculatorState.runningState) {
            case CoverageCheckerRunningState.INPUT_MANUAL:
                return uploadCsvBtn

            case CoverageCheckerRunningState.INPUT_CSV:
                return enterDataManuallyBtn

            case CoverageCheckerRunningState.PREVIEW_CSV:
                return (
                    <div className="flex items-center gap-4">
                        {enterDataManuallyBtn}

                        <ButtonElement
                            label="Re-upload CSV"
                            type="default"
                            size="large"
                            icon={refreshIcon}
                            htmlType="button"
                            onClick={onReset}
                        />
                    </div>
                )

            case CoverageCheckerRunningState.RESULTS_MANUAL:
            case CoverageCheckerRunningState.RESULTS_CSV:
                return (
                    <div className="flex items-center gap-4">
                        {uploadCsvBtn}

                        {enterDataManuallyBtn}
                    </div>
                )

            default:
                return null
        }
    }, [calculatorState.runningState])

    return (
        <div className="pb-32 relative">
            <PageHelmetElement
                title="Coverage Checker"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            <NirvanaBreathingLoaderElement isActive={isLoading} />

            {!profile || !availableCptCodes.length ? (
                <SpinnerElement containerClassName="text-center mt-48px" />
            ) : canRunCoverageChecks ? (
                <div>
                    <PrimaryText typography={isMobile ? "h2" : "h1"}>
                        {headerTitle}
                    </PrimaryText>

                    <div
                        className="
                            flex items-center mt-8px
                            md:block
                        "
                    >
                        <PrimaryText className="mr-128px max-w-480px md:max-w-full md:mr-0px">
                            {headerSubtitle}
                        </PrimaryText>

                        <div className="flex-1 md:mt-24px md:text-right">
                            {headerActions}
                        </div>
                    </div>

                    {!isLoading && <ResultsDownloadComponent />}

                    <div>
                        {calculatorState.checkerMode ===
                        CoverageCheckerMode.CSV ? (
                            <CoverageCheckerCSVComponent
                                isLoading={isLoading}
                            />
                        ) : (
                            <CoverageCheckerManualComponent className="mt-44px md:mt-24px" />
                        )}

                        <div
                            className={`
                                fixed z-50 right-8px bottom-8px sm:w-screen sm:pl-16px
                                ${
                                    !selectedPracticeRole?.monthlyCoverageQuotaLeft
                                        ? "w-400px"
                                        : "w-300px"
                                }
                            `}
                        >
                            {!selectedPracticeRole?.monthlyCoverageQuotaLeft ? (
                                <div className="rounded-8px bg-brand-lilacLight p-16px">
                                    Looks like you are approaching your
                                    organization's monthly credit limit for
                                    coverage checks. Reach out to{" "}
                                    <a
                                        href={`mailto:${GENERAL_CONFIG.supportEmail}`}
                                    >
                                        {GENERAL_CONFIG.supportEmail}
                                    </a>{" "}
                                    to explore options for increasing your
                                    credits.
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}
