import { NIRVANA_GENERAL_CONFIG } from "nirvana-react-elements"

export const GENERAL_CONFIG = {
    // Common values from our elements library that can be overridden here if needed
    ...NIRVANA_GENERAL_CONFIG,

    defaultPageTitle: "Coverage Portal",

    headerHeight: 74,

    subscriptionEmail: "subscription@meetnirvana.com",

    isStaging: process.env.REACT_APP_ENV === "stage",
    isSandbox: process.env.REACT_APP_ENV === "sandbox",
    isProduction: process.env.REACT_APP_ENV === "prod",

    urlSearchParamsKeys: {
        ...NIRVANA_GENERAL_CONFIG.urlSearchParamsKeys,

        checkerMode: "checkerMode",
        create: "create",
        results: "results",
        filters: "filters",
        ignoreListReload: "ignoreListReload",
        reloadPoliciesList: "reloadPoliciesList",
    },

    browserStorageKeys: {
        ...NIRVANA_GENERAL_CONFIG.browserStorageKeys,

        selectedPracticeId: "selectedPracticeId",

        historicalPoliciesColumnsConfiguration:
            "historicalPoliciesColumnsConfiguration",

        reportsPoliciesColumnsConfiguration:
            "reportsPoliciesColumnsConfiguration",
        reportsPoliciesFiltersConfiguration:
            "reportsPoliciesFiltersConfiguration",
        reportsVisualizationCollapsed: "reportsVisualizationCollapsed",
        reportsSavedConfigurations: "reportsSavedConfigurations",

        overridesPoliciesColumnsConfiguration:
            "overridesPoliciesColumnsConfiguration",
        overridesPoliciesFiltersConfiguration:
            "overridesPoliciesFiltersConfiguration",

        planYearResetsPoliciesColumnsConfiguration:
            "planYearResetsPoliciesColumnsConfiguration",
        planYearResetsPoliciesFiltersConfiguration:
            "planYearResetsPoliciesFiltersConfiguration",
        planYearResetsVisualizationCollapsed:
            "planYearResetsVisualizationCollapsed",
        planYearResetsSavedConfigurations: "planYearResetsSavedConfigurations",
    },

    defaultMomentDateFormatShortYear: "MM/DD/YY",
    defaultMomentDateFormatReadable: "MMM D. YYYY",

    eagleEye: {
        headers: {
            userId: "nirvana-eagle-eye-user-id",
            practiceId: "nirvana-eagle-eye-practice-id",
        },
    },
}
