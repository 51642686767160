/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import moment from "moment-timezone"
import {
    AutoCompleteElement,
    ButtonElement,
    CheckboxElement,
    DatePickerElement,
    InputElement,
    ISelectRenderedOption,
    LookupHelper,
    PrimaryText,
    SelectElement,
    UtilHelper,
    VALIDATION_CONFIG,
    InputErrorElement,
    NIRVANA_COLORS,
} from "nirvana-react-elements"

import {
    COVERAGE_CONFIG,
    PayerCoverageCheckNetwork,
} from "../../../config/coverage.config"
import { LookupService } from "../../../services/lookup.service"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { TOOLTIPS_CONFIG } from "../../../config/tooltips.config"
import { CoverageResultComponent } from "../result/coverageResult.component"
import { CustomNpiCollectionPopupComponent } from "../../popups/customNpiCollectionPopup.component"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { coveragePayersSelector } from "../../../store/selectors/coveragePayers.selector"

import trashIcon from "../../../assets/images/icons/trash-red.svg"
import editIcon from "../../../assets/images/icons/edit-dark-secondary.svg"
import closeIcon from "../../../assets/images/icons/close-dark.svg"

export const SingleCoverageCheckComponent: React.FunctionComponent<
    ISingleCoverageCheckComponentProps
> = props => {
    const coveragePayers = useAppSelector(coveragePayersSelector)
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const [maxDob] = useState<Date>(new Date())
    const [minNextAppointmentDate] = useState<Date>(new Date())
    const [isSettingCustomNpi, setIsSettingCustomNpi] = useState<boolean>(false)

    // Monitor network and make sure to set error if no network is selected
    // Ignore this logic if payer is not selected yet
    useEffect(() => {
        if (!props.dataWatcher.payer) {
            return
        }

        if (
            !props.dataWatcher.inNetworkCheck &&
            !props.dataWatcher.outNetworkCheck
        ) {
            props.reactHookSetError(`${props.fieldGroupName}.inNetworkCheck`, {
                type: "validate",
                message: "Network is required",
            })

            return
        }

        props.reactHookClearErrors(`${props.fieldGroupName}.inNetworkCheck`)
    }, [JSON.stringify(props.dataWatcher)])

    // Monitor input predefined data networks and make sure properly set them in state
    useEffect(() => {
        typeof props.stateCheckInputData?.inNetworkCheck !== "undefined" &&
            props.reactHookFormSet(
                `${props.fieldGroupName}.inNetworkCheck`,
                props.stateCheckInputData?.inNetworkCheck
            )

        typeof props.stateCheckInputData?.outNetworkCheck !== "undefined" &&
            props.reactHookFormSet(
                `${props.fieldGroupName}.outNetworkCheck`,
                props.stateCheckInputData?.outNetworkCheck
            )
    }, [
        props.stateCheckInputData?.inNetworkCheck,
        props.stateCheckInputData?.outNetworkCheck,
    ])

    const getMatchingCoveragePayer = (
        payer?: IPayer,
        preferredNetwork = PayerCoverageCheckNetwork.IN
    ): ICoveragePayer | undefined => {
        return (
            coveragePayers.find(
                item =>
                    item.payer.payerId === payer?.payerId &&
                    item.defaultCoverageCheckNetwork === preferredNetwork
            ) ||
            coveragePayers.find(item => item.payer.payerId === payer?.payerId)
        )
    }

    const availableCptCodeOptions = useMemo<ISelectRenderedOption[]>(() => {
        const preSavedPayerCodes = getMatchingCoveragePayer(
            props.dataWatcher.payer
        )?.rates.map(item => item.code)

        // First return preSaved payer values and then all other options
        return [
            ...props.cptCodes.filter(item =>
                preSavedPayerCodes?.includes(item.value)
            ),

            ...props.cptCodes.filter(
                item => !preSavedPayerCodes?.includes(item.value)
            ),
        ]
    }, [props.dataWatcher.payer])

    const onPayerSelected = (payer: IPayer) => {
        const coveragePayer = getMatchingCoveragePayer(payer)

        if (!coveragePayer) {
            return
        }

        switch (coveragePayer.defaultCoverageCheckNetwork) {
            case PayerCoverageCheckNetwork.IN:
                props.reactHookFormSet(
                    `${props.fieldGroupName}.inNetworkCheck`,
                    true
                )
                props.reactHookFormSet(
                    `${props.fieldGroupName}.outNetworkCheck`,
                    false
                )

                break

            case PayerCoverageCheckNetwork.OUT:
                props.reactHookFormSet(
                    `${props.fieldGroupName}.inNetworkCheck`,
                    false
                )
                props.reactHookFormSet(
                    `${props.fieldGroupName}.outNetworkCheck`,
                    true
                )

                break

            case PayerCoverageCheckNetwork.IN_OUT:
                props.reactHookFormSet(
                    `${props.fieldGroupName}.inNetworkCheck`,
                    true
                )
                props.reactHookFormSet(
                    `${props.fieldGroupName}.outNetworkCheck`,
                    true
                )

                break
        }

        setTimeout(() => {
            onCoveragePayerSelected(coveragePayer)
        })
    }

    const onCoveragePayerSelected = (coveragePayer: ICoveragePayer) => {
        props.reactHookFormSet(
            `${props.fieldGroupName}.payer`,
            coveragePayer.payer
        )

        if (!coveragePayer.rates.length) {
            return
        }

        onPayerRateSelected(coveragePayer.rates[0].code)
    }

    const onPayerRateSelected = (cptCode: string) => {
        // Set CPT code value
        props.reactHookFormSet(`${props.fieldGroupName}.cptCode`, cptCode, {
            shouldValidate: true,
        })

        const currentPayer = props.reactHookFormWatch(
            `${props.fieldGroupName}.payer`
        ) as IPayer

        const currentCoveragePayer = getMatchingCoveragePayer(currentPayer)

        const neededPayerRate = currentCoveragePayer?.rates.find(
            item => item.code === cptCode
        )

        // If CPT code has pre-saved value for sessionRate -> set is as well
        // Amount comes in cents here  -> so convert it to dollars
        neededPayerRate &&
            props.reactHookFormSet(
                `${props.fieldGroupName}.sessionRate`,
                UtilHelper.formatCents(neededPayerRate.amount).toString(),
                {
                    shouldValidate: true,
                }
            )
    }

    const onCustomNpiSetDone = (customNpi?: string) => {
        setIsSettingCustomNpi(false)

        props.reactHookFormSet(`${props.fieldGroupName}.customNpi`, customNpi)
    }

    const nameValidation = (fieldKey: string) => {
        return () => {
            const dataWatcher = props.reactHookFormWatch(
                `${props.dataRootKey}[${props.coverageCheckIndex}]`
            )

            return (
                !!dataWatcher?.memberId ||
                !!dataWatcher?.[fieldKey] ||
                `Required when member ID is not provided`
            )
        }
    }

    return (
        <div className={`relative ${props.className}`}>
            {/*COVERAGE CHECK RESULT*/}
            {props.stateCheckInputData && props.stateCheckResults && (
                <CoverageResultComponent
                    inputData={props.stateCheckInputData}
                    results={props.stateCheckResults}
                />
            )}

            {/*COVERAGE CHECK INPUT*/}
            <div
                className={`
                    p-24px bg-brand-warmLight05 min-h-130px
                    ${
                        props.stateCheckInputData && props.stateCheckResults
                            ? "hidden"
                            : ""
                    }
                `}
            >
                <fieldset
                    name={props.fieldGroupName}
                    key={props.fieldGroupName}
                >
                    <div
                        className="
                            flex items-center
                            md:block
                        "
                    >
                        <div
                            className="
                                relative flex-1 pr-40px border-r border-solid border-brand-warmShadow
                                md:border-none md:pr-0px
                            "
                        >
                            <div className="mt-28px flex sm:block">
                                <div className="flex-1 mr-16px sm:mr-0px">
                                    <AutoCompleteElement
                                        name="payer"
                                        fieldGroupName={props.fieldGroupName}
                                        label="Payer"
                                        tooltip={
                                            TOOLTIPS_CONFIG.calculate.payer
                                        }
                                        placeholder=""
                                        disabled={props.checkDisabled}
                                        dataFetcher={(search?: string) =>
                                            LookupService.lookupPayers(search)
                                        }
                                        itemRenderer={
                                            LookupHelper.getRenderedPayer
                                        }
                                        reactHookFormErrors={
                                            props.fieldGroupErrors
                                        }
                                        reactHookFormRegister={
                                            props.reactHookFormRegister
                                        }
                                        reactHookFormUnregister={
                                            props.reactHookFormUnregister
                                        }
                                        reactHookFormSet={
                                            props.reactHookFormSet
                                        }
                                        debounceMilliseconds={250}
                                        defaultValue={
                                            props.stateCheckInputData?.payer
                                                ? LookupHelper.getRenderedPayer(
                                                      props.stateCheckInputData
                                                          .payer
                                                  ).displayValue
                                                : undefined
                                        }
                                        validations={{
                                            required:
                                                VALIDATION_CONFIG.required,
                                        }}
                                        shouldValidate
                                        onSelected={onPayerSelected}
                                        notFoundTitle="Don’t see your payer?"
                                        notFoundSubtitle={`
                                            <a
                                                href="mailto:${GENERAL_CONFIG.supportEmail}?subject=Insurance provider not found"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Contact Support
                                            </a>
                                        `}
                                        notFoundAction={() => (
                                            <a
                                                href={
                                                    GENERAL_CONFIG.supportedInsurersUrl
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <ButtonElement
                                                    label="View Supported Payers"
                                                    type="primary"
                                                    htmlType="button"
                                                    size="large"
                                                />
                                            </a>
                                        )}
                                        isLabelStatic
                                    />

                                    <a
                                        href={
                                            GENERAL_CONFIG.supportedInsurersUrl
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                        className="no-underline!"
                                    >
                                        <PrimaryText
                                            className="mt-8px"
                                            typography="caption"
                                        >
                                            View all supported payers
                                        </PrimaryText>
                                    </a>
                                </div>

                                <InputElement
                                    className="flex-1 sm:mt-44px"
                                    label="Member ID (recommended)"
                                    name="memberId"
                                    fieldGroupName={props.fieldGroupName}
                                    disabled={props.checkDisabled}
                                    reactHookErrors={props.fieldGroupErrors}
                                    reactHookControl={props.reactHookControl}
                                    reactHookValidations={{
                                        maxLength: VALIDATION_CONFIG.maxLength,
                                    }}
                                    defaultValue={
                                        props.stateCheckInputData?.memberId ||
                                        props.dataWatcher.memberId
                                    }
                                    tooltip={
                                        !props.dataWatcher.memberId
                                            ? TOOLTIPS_CONFIG.calculate.memberId
                                            : undefined
                                    }
                                    isLabelStatic
                                />
                            </div>

                            <div
                                className="
                                    flex mt-44px
                                    md:block
                                "
                            >
                                <DatePickerElement
                                    className="
                                        mr-16px flex-1
                                        md:mr-0px
                                    "
                                    name="dob"
                                    fieldGroupName={props.fieldGroupName}
                                    disabled={props.checkDisabled}
                                    label="Date of Birth"
                                    defaultValue={
                                        props.stateCheckInputData?.dob
                                            ? moment(
                                                  props.stateCheckInputData.dob
                                              )
                                            : undefined
                                    }
                                    reactHookFormValidations={{
                                        required: VALIDATION_CONFIG.required,
                                    }}
                                    shouldValidate
                                    reactHookFormErrors={props.fieldGroupErrors}
                                    reactHookFormRegister={
                                        props.reactHookFormRegister
                                    }
                                    reactHookFormUnregister={
                                        props.reactHookFormUnregister
                                    }
                                    reactHookFormSet={props.reactHookFormSet}
                                    maxDate={maxDob}
                                    isLabelStatic
                                />

                                <InputElement
                                    className="
                                        mr-16px flex-1
                                        md:mr-0px md:mt-44px
                                    "
                                    label={`Member First Name${
                                        props.dataWatcher.memberId
                                            ? " (optional)"
                                            : ""
                                    }`}
                                    name="firstName"
                                    tooltip={
                                        TOOLTIPS_CONFIG.calculate.firstName
                                    }
                                    fieldGroupName={props.fieldGroupName}
                                    disabled={props.checkDisabled}
                                    reactHookControl={props.reactHookControl}
                                    reactHookValidations={{
                                        maxLength: VALIDATION_CONFIG.maxLength,
                                        validate: nameValidation("firstName"),
                                    }}
                                    reactHookErrors={props.fieldGroupErrors}
                                    defaultValue={
                                        props.stateCheckInputData?.firstName ||
                                        props.dataWatcher.firstName
                                    }
                                    isLabelStatic
                                />

                                <InputElement
                                    className="
                                        flex-1
                                        md:mt-44px
                                    "
                                    label={`Member Last Name${
                                        props.dataWatcher.memberId
                                            ? " (optional)"
                                            : ""
                                    }`}
                                    name="lastName"
                                    tooltip={TOOLTIPS_CONFIG.calculate.lastName}
                                    fieldGroupName={props.fieldGroupName}
                                    disabled={props.checkDisabled}
                                    reactHookValidations={{
                                        maxLength: VALIDATION_CONFIG.maxLength,
                                        validate: nameValidation("lastName"),
                                    }}
                                    reactHookErrors={props.fieldGroupErrors}
                                    defaultValue={
                                        props.stateCheckInputData?.lastName ||
                                        props.dataWatcher.lastName
                                    }
                                    reactHookControl={props.reactHookControl}
                                    isLabelStatic
                                />
                            </div>

                            <div
                                className="
                                    flex mt-44px
                                    md:block
                                "
                            >
                                <div className="flex flex-1">
                                    <SelectElement
                                        className="
                                            flex-1 mr-16px
                                            md:w-full md:mr-0px
                                        "
                                        label="CPT Code"
                                        placeholder="Select one"
                                        name="cptCode"
                                        fieldGroupName={props.fieldGroupName}
                                        externalValue={
                                            props.dataWatcher.cptCode
                                        }
                                        reactHookFormControl={
                                            props.reactHookControl
                                        }
                                        validations={{
                                            required:
                                                VALIDATION_CONFIG.required,
                                        }}
                                        disabled={
                                            availableCptCodeOptions.length <
                                                2 || props.checkDisabled
                                        }
                                        renderedOptions={
                                            availableCptCodeOptions
                                        }
                                        onSelected={cptCode =>
                                            onPayerRateSelected(cptCode)
                                        }
                                    />

                                    <InputElement
                                        className="
                                            w-170px mr-8px
                                            md:w-full md:mr-0px md:mt-44px
                                        "
                                        type="number"
                                        label="Session Rate"
                                        name="sessionRate"
                                        fieldGroupName={props.fieldGroupName}
                                        disabled={props.checkDisabled}
                                        reactHookErrors={props.fieldGroupErrors}
                                        reactHookControl={
                                            props.reactHookControl
                                        }
                                        reactHookValidations={{
                                            required:
                                                VALIDATION_CONFIG.required,
                                            min: VALIDATION_CONFIG.minAmount,
                                        }}
                                        defaultValue={
                                            props.stateCheckInputData
                                                ?.sessionRate ||
                                            props.dataWatcher.sessionRate
                                        }
                                        inputPrefix={<>$</>}
                                        isLabelStatic
                                    />
                                </div>

                                <div className="flex-basis-33" />
                            </div>

                            <div
                                className="
                                    flex mt-44px
                                    md:block
                                "
                            >
                                <SelectElement
                                    className="
                                        mr-16px flex-1
                                        md:mr-0px
                                    "
                                    label="Patient Type (optional)"
                                    name="customerPatientType"
                                    fieldGroupName={props.fieldGroupName}
                                    allowClear={true}
                                    reactHookFormRegister={
                                        props.reactHookFormRegister
                                    }
                                    reactHookFormSet={props.reactHookFormSet}
                                    reactHookFormUnregister={
                                        props.reactHookFormUnregister
                                    }
                                    renderedOptions={Object.values(
                                        COVERAGE_CONFIG.selectRenderedPatientTypes
                                    )}
                                    defaultValue={
                                        props.stateCheckInputData
                                            ?.customerPatientType
                                            ? COVERAGE_CONFIG
                                                  .selectRenderedPatientTypes[
                                                  props.stateCheckInputData
                                                      .customerPatientType
                                              ].displayValue
                                            : undefined
                                    }
                                />

                                <InputElement
                                    className="
                                        mr-16px flex-1
                                        md:mr-0px md:mt-44px
                                    "
                                    label="Patient ID (optional)"
                                    name="customerPatientId"
                                    fieldGroupName={props.fieldGroupName}
                                    disabled={props.checkDisabled}
                                    reactHookControl={props.reactHookControl}
                                    reactHookValidations={{
                                        maxLength: VALIDATION_CONFIG.maxLength,
                                    }}
                                    reactHookErrors={props.fieldGroupErrors}
                                    defaultValue={
                                        props.stateCheckInputData
                                            ?.customerPatientId ||
                                        props.dataWatcher.customerPatientId
                                    }
                                    isLabelStatic
                                />

                                <DatePickerElement
                                    className="
                                        flex-1
                                        md:mt-44px
                                    "
                                    name="customerPatientNextAppointmentDate"
                                    fieldGroupName={props.fieldGroupName}
                                    disabled={props.checkDisabled}
                                    label="Next Appointment Date (optional)"
                                    defaultValue={
                                        props.stateCheckInputData
                                            ?.customerPatientNextAppointmentDate
                                            ? moment(
                                                  props.stateCheckInputData
                                                      .customerPatientNextAppointmentDate
                                              )
                                            : undefined
                                    }
                                    reactHookFormErrors={props.fieldGroupErrors}
                                    reactHookFormRegister={
                                        props.reactHookFormRegister
                                    }
                                    reactHookFormUnregister={
                                        props.reactHookFormUnregister
                                    }
                                    reactHookFormSet={props.reactHookFormSet}
                                    minDate={minNextAppointmentDate}
                                    isLabelStatic
                                    allowClear
                                />
                            </div>
                        </div>

                        <div
                            className="
                                w-344px relative h-full
                                pl-24px
                                md:mt-32px md:bg-brand-white md:rounded-8px md:w-auto md:p-16px md:pb-32px
                            "
                        >
                            {!props.dataWatcher.payer ? (
                                <PrimaryText className="opacity-75">
                                    We’ll automatically fill out provider
                                    information here, after you fill out the
                                    patient information.
                                </PrimaryText>
                            ) : (
                                <>
                                    {/*CUSTOM NPI HOLDER*/}
                                    <InputElement
                                        type="hidden"
                                        name="customNpi"
                                        fieldGroupName={props.fieldGroupName}
                                        reactHookErrors={props.fieldGroupErrors}
                                        reactHookControl={
                                            props.reactHookControl
                                        }
                                        defaultValue={
                                            props.stateCheckInputData?.customNpi
                                        }
                                    />

                                    <div className="flex sm:block">
                                        <div className="w-122px mr-28px sm:mr-0px">
                                            <PrimaryText typography="textSemibold">
                                                {props.dataWatcher.customNpi
                                                    ? "Custom"
                                                    : "Group"}{" "}
                                                NPI
                                            </PrimaryText>
                                        </div>

                                        <PrimaryText
                                            className="flex-1 mr-32px md:flex-none"
                                            typography="text"
                                        >
                                            {props.dataWatcher.customNpi ||
                                                selectedPracticeRole?.practice
                                                    .groupNPI}
                                        </PrimaryText>

                                        {/*EDIT / REMOVE custom NPI*/}
                                        {props.dataWatcher.customNpi && (
                                            <div className="flex items-center">
                                                <img
                                                    src={closeIcon}
                                                    alt="Remove"
                                                    className="mr-16px cursor-pointer"
                                                    onClick={() =>
                                                        onCustomNpiSetDone()
                                                    }
                                                />

                                                <img
                                                    src={editIcon}
                                                    alt="Edit"
                                                    className="cursor-pointer"
                                                    onClick={() =>
                                                        setIsSettingCustomNpi(
                                                            true
                                                        )
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>

                                    {!props.dataWatcher.customNpi && (
                                        <div
                                            className="flex items-center mt-8px cursor-pointer sm:mt-16px"
                                            onClick={() =>
                                                setIsSettingCustomNpi(true)
                                            }
                                        >
                                            <PrimaryText
                                                className="mr-8px"
                                                typography="textBold"
                                            >
                                                Custom NPI
                                            </PrimaryText>

                                            <img
                                                src={editIcon}
                                                alt="Custom NPI"
                                            />
                                        </div>
                                    )}

                                    <div className="mt-32px flex relative">
                                        <CheckboxElement
                                            className="mr-40px"
                                            checkboxClassName="flex! items-start!"
                                            name="inNetworkCheck"
                                            fieldGroupName={
                                                props.fieldGroupName
                                            }
                                            reactHookFormRegister={
                                                props.reactHookFormRegister
                                            }
                                            reactHookFormUnregister={
                                                props.reactHookFormUnregister
                                            }
                                            reactHookFormSet={
                                                props.reactHookFormSet
                                            }
                                            reactHookValidations={{
                                                validate: () => {
                                                    const dataWatcher =
                                                        props.reactHookFormWatch(
                                                            `${props.dataRootKey}[${props.coverageCheckIndex}]`
                                                        )

                                                    return (
                                                        dataWatcher?.inNetworkCheck ||
                                                        dataWatcher?.outNetworkCheck ||
                                                        "Network is required"
                                                    )
                                                },
                                            }}
                                            disabled={props.checkDisabled}
                                            watchDefaultCheckedChange
                                            defaultChecked={
                                                typeof props.dataWatcher
                                                    .inNetworkCheck ===
                                                "undefined"
                                                    ? true
                                                    : props.dataWatcher
                                                          .inNetworkCheck
                                            }
                                            label={
                                                <PrimaryText>INN</PrimaryText>
                                            }
                                        />

                                        <CheckboxElement
                                            checkboxClassName="flex! items-start!"
                                            name="outNetworkCheck"
                                            fieldGroupName={
                                                props.fieldGroupName
                                            }
                                            reactHookFormRegister={
                                                props.reactHookFormRegister
                                            }
                                            reactHookFormUnregister={
                                                props.reactHookFormUnregister
                                            }
                                            reactHookFormSet={
                                                props.reactHookFormSet
                                            }
                                            disabled={props.checkDisabled}
                                            watchDefaultCheckedChange
                                            defaultChecked={
                                                props.dataWatcher
                                                    .outNetworkCheck
                                            }
                                            label={
                                                <PrimaryText>OON</PrimaryText>
                                            }
                                        />

                                        {!props.dataWatcher.outNetworkCheck &&
                                        !props.dataWatcher.inNetworkCheck ? (
                                            <InputErrorElement
                                                className="absolute left-0 top-24px"
                                                text="Network is required"
                                            />
                                        ) : null}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </fieldset>

                {/*DELETE BTN*/}
                {props.removeRowAvailable && !props.checkDisabled ? (
                    <div
                        className="flex items-center w-150px mt-24px cursor-pointer"
                        onClick={() =>
                            props.onRemoveRow?.(props.coverageCheckIndex)
                        }
                    >
                        <PrimaryText
                            typography="textSemibold"
                            color={NIRVANA_COLORS.brand.error}
                            className="mr-8px"
                        >
                            Remove Check
                        </PrimaryText>

                        <div className="relative top--2px">
                            <img src={trashIcon} alt="Remove check" />
                        </div>
                    </div>
                ) : null}

                {/*CUSTOM NPI COLLECTION POPUP*/}
                <CustomNpiCollectionPopupComponent
                    onDone={onCustomNpiSetDone}
                    isActive={isSettingCustomNpi}
                />
            </div>
        </div>
    )
}
