export const API_ROUTES = {
    ////////////////////////////////////////////////////////////////////////////////////// AUTH
    AUTH_EMAIL_AVAILABLE: "/coverage-portal/auth/email-available",
    AUTH_REGISTER: "/coverage-portal/auth/register",
    AUTH_LOGIN: "/coverage-portal/auth/login",
    AUTH_FORGOT_PASSWORD: "/coverage-portal/auth/forgot-password",
    AUTH_FORGOT_PASSWORD_PROCESS:
        "/coverage-portal/auth/forgot-password/process",
    AUTH_VERIFY_EMAIL: "/coverage-portal/auth/verify-email",
    AUTH_RESEND_VERIFY_RESEND: "/coverage-portal/auth/verify-resend",
    AUTH_CONFIRM_USER: "/coverage-portal/auth/confirm-user",
    AUTH_CHECK_INVITE_TOKEN: "/coverage-portal/auth/invites/:inviteToken",

    ////////////////////////////////////////////////////////////////////////////////////// PROFILE
    PROFILE_GET: "/coverage-portal/profile",
    PROFILE_ACCEPT_ORG_INVITATION:
        "/coverage-portal/profile/invites/:inviteToken/accept",

    ////////////////////////////////////////////////////////////////////////////////////// ORGANIZATION USERS
    ORGANIZATION_USERS_GET_LIST: "/coverage-portal/practices/:practiceId/users",
    ORGANIZATION_USERS_DELETE:
        "/coverage-portal/practices/:practiceId/users/:userId",
    ORGANIZATION_USERS_CHANGE_ROLE:
        "/coverage-portal/practices/:practiceId/users/:userId/role",
    ORGANIZATION_USERS_INVITE: "/coverage-portal/practices/:practiceId/invites",
    ORGANIZATION_USERS_GET_INVITES_LIST:
        "/coverage-portal/practices/:practiceId/invites",
    ORGANIZATION_USERS_DELETE_INVITE:
        "/coverage-portal/practices/:practiceId/invites/:inviteId",
    ORGANIZATION_USERS_CHANGE_INVITE_ROLE:
        "/coverage-portal/practices/:practiceId/invites/:inviteId/role",

    ////////////////////////////////////////////////////////////////////////////////////// PAYERS
    PAYERS_GET_LIST: "/coverage-portal/practices/:practiceId/payers",
    PAYERS_CREATE_INSTANCE: "/coverage-portal/practices/:practiceId/payers",
    PAYERS_UPDATE_INSTANCE:
        "/coverage-portal/practices/:practiceId/payers/:payerId",
    PAYERS_DELETE_INSTANCE:
        "/coverage-portal/practices/:practiceId/payers/:payerId",

    ////////////////////////////////////////////////////////////////////////////////////// COVERAGE
    COVERAGE_GET_ESTIMATE: "/coverage-portal/practices/:practiceId/estimate",
    COVERAGE_GET_SMART_SCAN_ESTIMATE:
        "/coverage-portal/practices/:practiceId/estimate/smart-scan",

    ////////////////////////////////////////////////////////////////////////////////////// BILLER GROUPS
    BILLER_GROUPS_GET_LIST:
        "/coverage-portal/practices/:practiceId/biller-groups",
    BILLER_GROUPS_CREATE:
        "/coverage-portal/practices/:practiceId/biller-groups",
    BILLER_GROUPS_GET_SINGLE:
        "/coverage-portal/practices/:practiceId/biller-groups/:billerGroupId",
    BILLER_GROUPS_UPDATE_SINGLE:
        "/coverage-portal/practices/:practiceId/biller-groups/:billerGroupId",
    BILLER_GROUPS_DELETE_SINGLE:
        "/coverage-portal/practices/:practiceId/biller-groups/:billerGroupId",
    BILLER_GROUPS_UPDATE_USERS:
        "/coverage-portal/practices/:practiceId/biller-groups/:billerGroupId/users",

    ////////////////////////////////////////////////////////////////////////////////////// COVERAGE CHECKS HISTORY
    COVERAGE_HISTORY_GET_LIST: "/coverage-portal/practices/:practiceId/history",
    COVERAGE_HISTORY_GET_SINGLE:
        "/coverage-portal/practices/:practiceId/history/:nirvanaRequestId",
    COVERAGE_HISTORY_RETRY_SINGLE:
        "/coverage-portal/practices/:practiceId/history/:nirvanaRequestId/retry",
    COVERAGE_REPORTS_GET_LIST:
        "/coverage-portal/practices/:practiceId/history/reports",
    COVERAGE_REPORTS_GET_AGGREGATION_STATUS_CODES:
        "/coverage-portal/practices/:practiceId/history/reports/aggregations/status-codes",
    COVERAGE_REPORTS_GET_AGGREGATION_FLAGS:
        "/coverage-portal/practices/:practiceId/history/reports/aggregations/flags",
    COVERAGE_PLAN_YEAR_RESETS_GET_LIST:
        "/coverage-portal/practices/:practiceId/history/plan-year-resets",
    COVERAGE_PLAN_YEAR_RESETS_GET_AGGREGATIONS:
        "/coverage-portal/practices/:practiceId/history/reports/aggregations/plan-year-resets",
    COVERAGE_OVERRIDES_GET_LIST:
        "/coverage-portal/practices/:practiceId/history",

    ////////////////////////////////////////////////////////////////////////////////////// COVERAGE CHECKS COMMENTS
    COMMENTS_GET_LIST: "/coverage-portal/practices/:practiceId/comments",
    COMMENTS_CREATE: "/coverage-portal/practices/:practiceId/comments",
    COMMENTS_UPDATE_SINGLE:
        "/coverage-portal/practices/:practiceId/comments/:commentId",
    COMMENTS_DELETE_SINGLE:
        "/coverage-portal/practices/:practiceId/comments/:commentId",

    ////////////////////////////////////////////////////////////////////////////////////// COVERAGE CHECKS FLAGS
    COVERAGE_FLAGS_RESOLVE_SINGLE:
        "/coverage-portal/practices/:practiceId/flags/:flagId/resolve",

    ////////////////////////////////////////////////////////////////////////////////////// COVERAGE OVERRIDES
    COVERAGE_OVERRIDES_CREATE:
        "/coverage-portal/practices/:practiceId/overrides",
    COVERAGE_OVERRIDES_GET_SINGLE:
        "/coverage-portal/practices/:practiceId/overrides",
    COVERAGE_OVERRIDES_DELETE:
        "/coverage-portal/practices/:practiceId/overrides/:overrideNirvanaRequestId",

    ////////////////////////////////////////////////////////////////////////////////////// FEATURE FLAGS
    FEATURE_FLAGS_GET_ENABLED_LIST:
        "/coverage-portal/practices/:practiceId/feature-flags/enabled",

    ////////////////////////////////////////////////////////////////////////////////////// SUPPORT
    SUPPORT_REPORT_COVERAGE_RESULT:
        "/coverage-portal/practices/:practiceId/support/report-result",
}

const authUrl = "/auth"

export const ROUTES_CONFIG = {
    baseApplicationUrl: "/",

    authUrl,
    loginUrl: `${authUrl}/login`,
    logoutUrl: `${authUrl}/logout`,
    verifyEmailUrl: `${authUrl}/verify-email`,
    confirmUserUrl: `${authUrl}/confirm-user`,

    registerUrl: `${authUrl}/register`,
    registerSuccessUrl: `${authUrl}/register/success`,

    restoreUrl: `${authUrl}/restore`,
    restoreProcessUrl: `${authUrl}/restore/process`,

    coverageCheckerUrl: "/coverage-checker",
    organizationUrl: "/organization",

    searchUrl: "/search",
    searchSinglePolicyUrl: "/search/:nirvanaRequestId",

    reportsUrl: "/reports",
    reportsSinglePolicyUrl: "/reports/:nirvanaRequestId",

    overridesUrl: "/overrides",
    overridesSinglePolicyUrl: "/overrides/:nirvanaRequestId",

    planYearResetsUrl: "/plan-year-resets",
    planYearResetsSinglePolicyUrl: "/plan-year-resets/:nirvanaRequestId",

    profileUrl: "/profile",
    supportUrl: "/support",
}

// Make coverage checker a default url in application
ROUTES_CONFIG.baseApplicationUrl = ROUTES_CONFIG.coverageCheckerUrl

export const ROUTES_MENU_FREE_CONFIG = [] as string[]

// Route that shouldn't have left and right margins on the screen
export const ROUTES_FULL_SCREEN_WIDTH = [
    ROUTES_CONFIG.organizationUrl,
    ROUTES_CONFIG.searchUrl,
    ROUTES_CONFIG.searchSinglePolicyUrl,
    ROUTES_CONFIG.reportsUrl,
    ROUTES_CONFIG.reportsSinglePolicyUrl,
    ROUTES_CONFIG.overridesUrl,
    ROUTES_CONFIG.overridesSinglePolicyUrl,
    ROUTES_CONFIG.planYearResetsUrl,
    ROUTES_CONFIG.planYearResetsSinglePolicyUrl,
]
