/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react"
import { SpinnerElement } from "nirvana-react-elements"

import { useAppSelector } from "../../../store/selectors/app.selector"
import { runtimeSelector } from "../../../store/selectors/runtime.selector"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { policiesSelector } from "../../../store/selectors/policies.selector"
import { policiesGetPlanYearResetsAggregations } from "../../../store/thunks/policies.thunks"
import { PlanYearResetVisualizationGeneralComponent } from "./planYearResetVisualizationGeneral.component"
import { PlanYearResetVisualizationPayersComponent } from "./planYearResetVisualizationPayers.component"

export const PlanYearResetVisualizationComponent: React.FunctionComponent<{
    className?: string
}> = props => {
    const dispatch = useAppDispatch()

    const runtimeState = useAppSelector(runtimeSelector)
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const { planYearResetsFilters } = useAppSelector(policiesSelector)

    const isLoading = useMemo<boolean>(() => {
        return RuntimeHelper.isPlanYearResetsAggregationsLoading()
    }, [runtimeState.isLoading])

    useEffect(() => {
        if (!selectedPracticeRole) {
            return
        }

        const thunkPromise = dispatch(
            policiesGetPlanYearResetsAggregations({
                practice: selectedPracticeRole.practice,
                payload: {
                    filters: planYearResetsFilters,
                },
            })
        )

        return () => {
            thunkPromise.abort()
        }
    }, [
        selectedPracticeRole?.practice.id,
        JSON.stringify(planYearResetsFilters),
    ])

    return (
        <div
            className={`
                relative min-h-150px bg-brand-white
                ${props.className}
            `}
        >
            {isLoading && (
                <div
                    className="
                        absolute z-10 w-full h-full bg-brand-whiteTransparent85
                        flex items-center justify-center
                    "
                >
                    <SpinnerElement />
                </div>
            )}

            <div
                className="
                    flex justify-center py-40px px-24px
                    md:block
                "
            >
                <PlanYearResetVisualizationGeneralComponent />

                <PlanYearResetVisualizationPayersComponent className="md:mt-32px" />
            </div>
        </div>
    )
}
