/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import { PrimaryText } from "nirvana-react-elements"

export const PoliciesAggregationsChartLegendComponent: React.FunctionComponent<
    IPoliciesAggregationsChartLegendComponentProps
> = props => {
    return (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            {props.label ? (
                <PrimaryText typography="subtitleSemibold">
                    {props.label}
                </PrimaryText>
            ) : null}

            {props.data.map((item, index) => {
                return (
                    <div
                        key={index}
                        className={`
                            ${props.compact ? "mt-8px" : "mt-12px"}
                            flex items-center cursor-default
                        `}
                        onMouseEnter={() => props.onLabelHover?.(item.label)}
                        onMouseLeave={() => props.onLabelHover?.(undefined)}
                    >
                        <div
                            className="w-15px h-15px rounded-4px mr-8px relative top--2px"
                            style={{
                                backgroundColor: item.color,
                            }}
                        />

                        <PrimaryText>{item.label}</PrimaryText>

                        <div className="flex-1" />

                        <PrimaryText>
                            <span className="text-bold">{item.value}</span> (
                            {item.percentage}%)
                        </PrimaryText>
                    </div>
                )
            })}
        </div>
    )
}
