/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import {
    BrowserStorageType,
    ButtonElement,
    CheckboxElement,
    ConfirmationHelper,
    InputElement,
    PrimaryText,
    VALIDATION_CONFIG,
    NIRVANA_COLORS,
} from "nirvana-react-elements"
import useOnclickOutside from "react-cool-onclickoutside"
import { useForm } from "react-hook-form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFolderOpen } from "@fortawesome/free-regular-svg-icons"
import moment from "moment-timezone"

import { BrowserStorageHelper } from "../../../helpers/browserStorageHelper"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { POLICIES_CONFIG } from "../../../config/policies.config"
import { PoliciesHelper } from "../../../helpers/policies.helper"

import downloadIcon from "../../../assets/images/icons/download-white.svg"
import closeIcon from "../../../assets/images/icons/close-dark.svg"
import trashIcon from "../../../assets/images/icons/trash-red.svg"

export const PoliciesSavedConfigurationsComponent: React.FunctionComponent<
    IPoliciesSavedConfigurationsComponentProps
> = props => {
    const containerRef = useOnclickOutside(
        () => !isDeleteConfirmActive && setIsPopupActive(false)
    )

    const {
        handleSubmit,
        formState: { errors },
        control,
        register,
        unregister,
        setValue,
        reset,
        getValues,
    } = useForm<ISavePolicyConfigurationFormData | any>({
        defaultValues: {
            isRelativeDates: true,
            isRelativeNextAppointmentDates: true,
        },
    })

    const [isPopupActive, setIsPopupActive] = useState<boolean>(false)
    const [isDeleteConfirmActive, setIsDeleteConfirmActive] =
        useState<boolean>(false)

    const getConfigurationsFromStorage = (): IPoliciesSavedConfiguration[] => {
        const configurations: IPoliciesSavedConfiguration[] =
            BrowserStorageHelper.get(
                props.browserStorageKey,
                undefined,
                BrowserStorageType.localStorage
            ) || []

        return configurations.map(item => {
            return {
                ...item,

                columns:
                    BrowserStorageHelper.extendColumnsConfigurationsWithFunctionValues(
                        item.columns,
                        props.defaultColumns
                    ),
            }
        })
    }

    const [savedConfigurations, setSavedConfigurations] = useState<
        IPoliciesSavedConfiguration[]
    >(getConfigurationsFromStorage())

    // Not allowed if filters contain member data
    const filtersSavingAllowed = useMemo<boolean>(() => {
        const providedMemberData = Object.keys(props.filters)
            .filter(key =>
                POLICIES_CONFIG.searchMemberDataFilters.includes(
                    key as keyof IPoliciesListFiltersData
                )
            )
            .map(key => props.filters[key])
            .filter(item => !!item)

        return !providedMemberData.length
    }, [props.filters])

    // When popup becomes active - make sure to load latest configurations from storage
    useEffect(() => {
        if (!isPopupActive) {
            return
        }

        setSavedConfigurations(getConfigurationsFromStorage())
    }, [isPopupActive, JSON.stringify(props.defaultColumns)])

    // Once configurations change in state - save in local storage
    useEffect(() => {
        BrowserStorageHelper.set(
            props.browserStorageKey,
            savedConfigurations,
            BrowserStorageType.localStorage
        )
    }, [JSON.stringify(savedConfigurations)])

    const onCancel = () => {
        setIsPopupActive(false)
    }

    const onCreateSaveCurrentConfiguration = (
        data: ISavePolicyConfigurationFormData
    ) => {
        if (!filtersSavingAllowed) {
            return
        }

        setSavedConfigurations(current => [
            {
                ...data,

                id: new Date().getTime(),
                columns: props.columns,
                filters: props.filters,
                createdAt: new Date().getTime(),
            },

            ...current,
        ])

        reset({
            isRelativeDates: getValues("isRelativeDates"),
            isRelativeNextAppointmentDates: getValues(
                "isRelativeNextAppointmentDates"
            ),
        })
    }

    const onDeleteSavedConfiguration = (
        configuration: IPoliciesSavedConfiguration
    ) => {
        setIsDeleteConfirmActive(true)

        ConfirmationHelper.enableConfirmation(
            () => {
                setSavedConfigurations(current =>
                    current.filter(item => item.id !== configuration.id)
                )

                setIsDeleteConfirmActive(false)
            },
            undefined,
            `Configuration will be permanently removed.`,
            () => {
                setIsDeleteConfirmActive(false)
            }
        )
    }

    const onConfigurationSelected = (
        configuration: IPoliciesSavedConfiguration
    ) => {
        props.onSavedConfigurationSelected(configuration)

        setIsPopupActive(false)
    }

    const isCurrentlySameConfiguration = (
        configuration: IPoliciesSavedConfiguration
    ) => {
        return (
            JSON.stringify(props.columns) ===
                JSON.stringify(configuration.columns) &&
            JSON.stringify(props.filters) ===
                JSON.stringify(
                    PoliciesHelper.getRelativeToTodaySavedConfigurationFilters(
                        configuration
                    )
                )
        )
    }

    return (
        <div
            ref={containerRef}
            className={`
                relative
                ${props.className}
            `}
        >
            <ButtonElement
                label="Saved Reports"
                onClick={() => setIsPopupActive(true)}
                htmlType="button"
            />

            {isPopupActive && (
                <div
                    className={`
                        no-body-scroll
                        fixed h-screen z-30 bg-brand-white shadow-7 overflow-hidden overflow-y-auto
                        w-365px sm:w-screen top-0
                        ${props.isRightPlacement ? "right-0" : "left-0"}
                    `}
                >
                    <div className="flex">
                        <div className="flex-1" />

                        <div
                            className="cursor-pointer p-24px"
                            onClick={onCancel}
                        >
                            <img src={closeIcon} alt="close" />
                        </div>
                    </div>

                    <div className="p-24px pt-8px border-b-1px border-gray-200">
                        <form
                            onSubmit={handleSubmit(
                                onCreateSaveCurrentConfiguration
                            )}
                            noValidate={true}
                        >
                            <PrimaryText typography="buttonText">
                                Saved reports
                            </PrimaryText>

                            <InputElement
                                className="w-full mt-40px"
                                name="name"
                                label="Name"
                                isLabelStatic
                                reactHookControl={control}
                                reactHookErrors={errors}
                                reactHookValidations={{
                                    required: VALIDATION_CONFIG.required,
                                    minLength: VALIDATION_CONFIG.minLength,
                                    maxLength: VALIDATION_CONFIG.maxLength,
                                }}
                            />

                            {props.filters.dateFrom || props.filters.dateTo ? (
                                <CheckboxElement
                                    className="mt-12px"
                                    checkboxClassName="flex! items-start!"
                                    name="isRelativeDates"
                                    reactHookFormRegister={register}
                                    reactHookFormUnregister={unregister}
                                    reactHookFormSet={setValue}
                                    defaultChecked={true}
                                    label={
                                        <PrimaryText>
                                            Change check dates relative to
                                            today's date
                                        </PrimaryText>
                                    }
                                />
                            ) : null}

                            {props.filters.nextAppointmentDateFrom ||
                            props.filters.nextAppointmentDateTo ? (
                                <CheckboxElement
                                    className="mt-12px"
                                    checkboxClassName="flex! items-start!"
                                    name="isRelativeNextAppointmentDates"
                                    reactHookFormRegister={register}
                                    reactHookFormUnregister={unregister}
                                    reactHookFormSet={setValue}
                                    defaultChecked={true}
                                    label={
                                        <PrimaryText>
                                            Change next appointment dates
                                            relative to today's date
                                        </PrimaryText>
                                    }
                                />
                            ) : null}

                            <ButtonElement
                                className="mt-16px"
                                buttonClassName="w-full"
                                label="Save"
                                type="primary"
                                htmlType="submit"
                                size="large"
                                icon={downloadIcon}
                                isRightIcon
                                disabled={!filtersSavingAllowed}
                            />

                            {!filtersSavingAllowed ? (
                                <PrimaryText
                                    className="mt-8px"
                                    typography="captionSemibold"
                                >
                                    Currently applied filters are not allowed to
                                    be saved since they contain patient data
                                </PrimaryText>
                            ) : null}
                        </form>
                    </div>

                    <div>
                        {!savedConfigurations.length ? (
                            <div className="mt-56px text-center">
                                <FontAwesomeIcon
                                    icon={faFolderOpen}
                                    color={NIRVANA_COLORS.brand.primary}
                                    size="lg"
                                />

                                <PrimaryText
                                    typography="h6"
                                    centered
                                    className="mt-8px"
                                >
                                    No saved reports yet
                                </PrimaryText>
                            </div>
                        ) : (
                            <div className="pb-32px">
                                {savedConfigurations.map(
                                    (configuration, index) => {
                                        const isDisabled =
                                            isCurrentlySameConfiguration(
                                                configuration
                                            )

                                        return (
                                            <div
                                                key={index}
                                                className={`
                                                    py-16px px-24px border-b border-solid border-brand-warmLight
                                                    flex items-center
                                                    ${
                                                        !isDisabled
                                                            ? "cursor-pointer bg-brand-white hover:bg-brand-warmLight05"
                                                            : "bg-brand-black-transparent-010"
                                                    }
                                                `}
                                                onClick={() =>
                                                    !isDisabled &&
                                                    onConfigurationSelected(
                                                        configuration
                                                    )
                                                }
                                            >
                                                <div className="flex-1 mr-8px w-0px">
                                                    <PrimaryText className="truncate">
                                                        {configuration.name}
                                                    </PrimaryText>

                                                    <PrimaryText typography="textSmall">
                                                        {isDisabled
                                                            ? "Same as current"
                                                            : moment(
                                                                  configuration.createdAt
                                                              ).format(
                                                                  GENERAL_CONFIG.defaultMomentDateTimeFormat
                                                              )}
                                                    </PrimaryText>
                                                </div>

                                                <div
                                                    className="cursor-pointer"
                                                    onClick={e => {
                                                        e.stopPropagation()

                                                        onDeleteSavedConfiguration(
                                                            configuration
                                                        )
                                                    }}
                                                >
                                                    <img
                                                        src={trashIcon}
                                                        alt="Remove"
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}
