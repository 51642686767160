/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react"
import {
    ButtonElement,
    PageHelmetElement,
    useScrollFix,
} from "nirvana-react-elements"

import {
    POLICIES_CONFIG,
    PoliciesViewType,
    PolicyHeaderDragDirection,
} from "../../../config/policies.config"
import { AvailableCoveragePortalPermission } from "../../../config/rolesPermissions.config"
import { useHasPermissions } from "../../../hooks/hasPermissions.hook"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { PoliciesListComponent } from "../policiesList/policiesList.component"
import { BrowserStorageHelper } from "../../../helpers/browserStorageHelper"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { policiesSetPlanYearResetsFilters } from "../../../store/slices/policies.slice"
import { PoliciesColumnsManagementComponent } from "../policiesList/policiesColumnsManagement.component"
import { PoliciesFilterPopupComponent } from "../../popups/policiesFilterPopup.component"
import { SearchFilterDisplayComponent } from "../search/searchFilterDisplay.component"
import { policiesSelector } from "../../../store/selectors/policies.selector"
import { PoliciesHelper } from "../../../helpers/policies.helper"
import { useScrollableElementBoundaries } from "../../../hooks/scrollableElementBoundaries.hook"
import { BulkOverridesComponent } from "../overrides/bulkOverrides/bulkOverrides.component"
import { usePoliciesList } from "../../../hooks/policiesList.hook"
import { policiesExportList } from "../../../store/thunks/policies.thunks"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { runtimeSelector } from "../../../store/selectors/runtime.selector"
import { VisualizationWrapperComponent } from "../visualizationWrapper.component"
import { PlanYearResetVisualizationComponent } from "./planYearResetVisualization.component"
import { PoliciesSavedConfigurationsComponent } from "../policiesList/policiesSavedConfigurations.component"

import filtersIcon from "../../../assets/images/icons/filters-dark.svg"
import exportIcon from "../../../assets/images/icons/download-up-dark.svg"

export const PlanYearResetsComponent: React.FunctionComponent = () => {
    useScrollFix()

    useHasPermissions([AvailableCoveragePortalPermission.viewPlanYearResets])

    const dispatch = useAppDispatch()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const runtimeState = useAppSelector(runtimeSelector)

    const {
        planYearResetsItems,
        planYearResetsFilters,
        policiesGettingFullListProgress,
    } = useAppSelector(policiesSelector)

    const scrollableTableRef = useRef<HTMLDivElement>(null)
    const scrollableTableBoundaries =
        useScrollableElementBoundaries(scrollableTableRef)

    const {
        // vars
        isOverridesEnabled,
        canManageOverrides,
        // state
        selectedPolicies,
        setSelectedPolicies,
        // functions
        onPoliciesSelected,
        onPoliciesDeselected,
        onDeselectAllPolicies,
    } = usePoliciesList()

    const [isFiltersPopupActive, setIsFiltersPopupActive] =
        useState<boolean>(false)

    // This component controls them and passes to list for display and header for modifications
    const [columnsConfiguration, setColumnsConfiguration] = useState<
        IPolicyColumnConfiguration[]
    >([])

    const listPoliciesSelectable = useMemo<boolean>(() => {
        return canManageOverrides && isOverridesEnabled
    }, [isOverridesEnabled, canManageOverrides])

    const showBulkOverrideButton = useMemo<boolean>(() => {
        return isOverridesEnabled && canManageOverrides
    }, [isOverridesEnabled, canManageOverrides])

    const isExportLoading = useMemo<boolean>(() => {
        return RuntimeHelper.isPoliciesExportLoading()
    }, [runtimeState.isLoading])

    // Change columns configurations once practice role modalities change
    useEffect(() => {
        if (!selectedPracticeRole) {
            return
        }

        setColumnsConfiguration(
            BrowserStorageHelper.getDefaultPlanYearResetsPoliciesColumnsConfiguration(
                selectedPracticeRole.availableModalities
            )
        )
    }, [selectedPracticeRole?.availableModalities])

    const onEditFilters = () => {
        // Deselect everything on filters change
        setSelectedPolicies([])

        setIsFiltersPopupActive(true)
    }

    const onFiltersSubmitted = (data: IPoliciesListFiltersData) => {
        dispatch(policiesSetPlanYearResetsFilters(data))
    }

    const onSaveColumnsConfiguration = (
        columns: IPolicyColumnConfiguration[]
    ) => {
        setColumnsConfiguration(columns)

        BrowserStorageHelper.saveColumnsConfigurations(
            GENERAL_CONFIG.browserStorageKeys
                .planYearResetsPoliciesColumnsConfiguration,
            columns,
            selectedPracticeRole?.availableModalities
        )
    }

    const onChangeColumnOrder = (
        columnLabelMoveSource: string,
        columnLabelMoveTarget: string,
        direction: PolicyHeaderDragDirection
    ) => {
        const newColumns = PoliciesHelper.changeSingleColumnOrdering(
            columnsConfiguration,
            columnLabelMoveSource,
            columnLabelMoveTarget,
            direction
        )

        onSaveColumnsConfiguration(newColumns)
    }

    const onExportCsv = () => {
        if (!selectedPracticeRole) {
            return
        }

        dispatch(
            policiesExportList({
                practice: selectedPracticeRole.practice,
                payload: {
                    viewType: PoliciesViewType.PLAN_YEAR_RESETS,
                    columns:
                        BrowserStorageHelper.getDefaultPlanYearResetsPoliciesColumnsConfiguration(
                            selectedPracticeRole.availableModalities
                        ),
                },
            })
        )
    }

    const onSavedConfigurationSelected = (
        configuration: IPoliciesSavedConfiguration
    ) => {
        if (
            JSON.stringify(columnsConfiguration) !==
            JSON.stringify(configuration.columns)
        ) {
            onSaveColumnsConfiguration(configuration.columns)
        }

        const newFilters =
            PoliciesHelper.getRelativeToTodaySavedConfigurationFilters(
                configuration
            )

        if (
            JSON.stringify(planYearResetsFilters) !== JSON.stringify(newFilters)
        ) {
            onFiltersSubmitted(newFilters)
        }
    }

    return (
        <div className="pb-32 relative">
            <PageHelmetElement
                title="Plan Year Resets"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            <div
                className="relative p-16px flex items-start"
                style={{
                    right: scrollableTableBoundaries?.x,
                }}
            >
                {!Object.keys(planYearResetsFilters).filter(
                    key =>
                        !!planYearResetsFilters[key] &&
                        !POLICIES_CONFIG.filtersHiddenDisplayKeys.includes(
                            key as keyof IPoliciesListFiltersData
                        )
                ).length ? (
                    <ButtonElement
                        label="Filters"
                        className="mr-16px"
                        onClick={onEditFilters}
                        htmlType="button"
                        type="default"
                        icon={filtersIcon}
                        isRightIcon
                    />
                ) : (
                    <SearchFilterDisplayComponent
                        className="w-380px mr-16px"
                        onEditFilters={onEditFilters}
                        existingFilters={planYearResetsFilters}
                    />
                )}

                <div className="flex-1 mx-16px" />

                {columnsConfiguration.length && selectedPracticeRole ? (
                    <>
                        <PoliciesColumnsManagementComponent
                            className="ml-16px"
                            initialColumnsConfiguration={PoliciesHelper.getDefaultPlanYearResetsColumns(
                                selectedPracticeRole.availableModalities
                            )}
                            currentColumnsConfiguration={columnsConfiguration}
                            onColumnsConfigurationChange={
                                onSaveColumnsConfiguration
                            }
                            isRightPlacement
                        />

                        <PoliciesSavedConfigurationsComponent
                            className="ml-16px"
                            columns={columnsConfiguration}
                            defaultColumns={BrowserStorageHelper.getDefaultPlanYearResetsPoliciesColumnsConfiguration(
                                selectedPracticeRole.availableModalities
                            )}
                            filters={planYearResetsFilters}
                            browserStorageKey={
                                GENERAL_CONFIG.browserStorageKeys
                                    .planYearResetsSavedConfigurations
                            }
                            isRightPlacement
                            onSavedConfigurationSelected={
                                onSavedConfigurationSelected
                            }
                        />
                    </>
                ) : null}

                <ButtonElement
                    label={`Export CSV${
                        isExportLoading
                            ? ` (${policiesGettingFullListProgress}%)`
                            : ""
                    }`}
                    className="ml-16px"
                    onClick={onExportCsv}
                    htmlType="button"
                    type="default"
                    icon={exportIcon}
                    isLoading={isExportLoading}
                    disabled={!planYearResetsItems.length}
                    isRightIcon
                />
            </div>

            {!!selectedPracticeRole && (
                <VisualizationWrapperComponent
                    className="mt-30px"
                    adjustHorizontalScrollReference={scrollableTableRef}
                    collapseStateBrowserStorageKey={
                        GENERAL_CONFIG.browserStorageKeys
                            .planYearResetsVisualizationCollapsed
                    }
                >
                    <PlanYearResetVisualizationComponent className="flex-1" />
                </VisualizationWrapperComponent>
            )}

            {selectedPracticeRole && columnsConfiguration.length ? (
                <PoliciesListComponent
                    viewType={PoliciesViewType.PLAN_YEAR_RESETS}
                    sortEnabled={true}
                    columns={columnsConfiguration}
                    scrollableElementRef={scrollableTableRef}
                    onChangeColumnOrder={onChangeColumnOrder}
                    policiesSelectable={listPoliciesSelectable}
                    selectedItems={selectedPolicies}
                    onSelectedItems={onPoliciesSelected}
                    onDeselectedItems={onPoliciesDeselected}
                />
            ) : null}

            {showBulkOverrideButton && (
                <BulkOverridesComponent
                    coverageChecksType={PoliciesViewType.PLAN_YEAR_RESETS}
                    onCancelBulkOverride={onDeselectAllPolicies}
                    coverageChecksToOverride={selectedPolicies}
                    setCoverageChecksToOverride={setSelectedPolicies}
                />
            )}

            {isFiltersPopupActive && (
                <PoliciesFilterPopupComponent
                    isActive
                    withFlags={false}
                    withResetBenefitsStatus={true}
                    existingFilters={planYearResetsFilters}
                    persistenceBrowserStorageKey={
                        GENERAL_CONFIG.browserStorageKeys
                            .planYearResetsPoliciesFiltersConfiguration
                    }
                    onFiltersSubmitted={onFiltersSubmitted}
                    onDone={() => setIsFiltersPopupActive(false)}
                    ignorePlanEndDateFilters
                />
            )}
        </div>
    )
}
