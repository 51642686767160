import React from "react"
import { Link } from "react-router-dom"
import { NIRVANA_COLORS, PrimaryText } from "nirvana-react-elements"

import { ROUTES_CONFIG } from "./routes.config"
import { GENERAL_CONFIG } from "./general.config"
import type { CoverageCheckerMode } from "./calculator.config"

export const TOOLTIPS_CONFIG = {
    auth: {
        registerEmailCollectionNote:
            "Your email will be used to create your account and for communication purposes. We will never market to your clients, and you can unsubscribe at any time.",
    },
    calculate: {
        sessionCharge:
            "If you’re contracted with the payer selected below, please enter your contracted rate. If you’re not contracted, please enter your cash-pay rate.",
        payer: "Please select the closest match to the name of the payer on insurance card.",
        firstName: "Make sure that first name matches insurance card",
        lastName: "Make sure that last name matches insurance card",
        memberId:
            "If the member ID isn’t available, Nirvana can try to locate the policy",
    },
    coverageBreakdown: {
        copayCoinsuranceUnknown:
            "If coinsurance or copay says N/A, don’t fret! It is common for clients to have one and not the other.",
        activeAtRiskPlanStatus:
            "A plan status of Active - At Risk indicates that the client’s insurance plan is either delinquent or under investigation and there is a risk the Insurer will not provide coverage for services rendered. The client should reach out to their insurance company and resolve any outstanding issues.",
        activePartialCoverage:
            "This plan is Active, however Nirvana does not yet support cost estimates on this plan.",
        thirdPartyPayer:
            "Payers who manage a specific health service of benefit excluded from the main insurance plan, i.e. carveouts.",
        additionalPolicy:
            "Additional insurance plans associated with the patient, i.e. secondary plans.",
    },
    bulkOverrides: {
        eligibleForBulkOverridePolicies:
            "Please note that only policies that are eligible for bulk overrides can be selected.",
    },
    singlePolicyView: {
        cantReRunCheckText: {
            title: "Why can’t I run a check?",
            // Made a function to get value and imported just type at top
            // Otherwise there's circular dependency
            text: (checkerMode: CoverageCheckerMode) => {
                return (
                    <PrimaryText
                        typography="h6"
                        centered
                        color={NIRVANA_COLORS.brand.white}
                    >
                        Please confirm that the payer and member information are
                        correct and{" "}
                        <Link
                            to={
                                ROUTES_CONFIG.coverageCheckerUrl +
                                `?${GENERAL_CONFIG.urlSearchParamsKeys.checkerMode}=${checkerMode}`
                            }
                        >
                            resubmit
                        </Link>
                        .
                    </PrimaryText>
                )
            },
        },
    },
}
